
export const OP_REASONS = {
    AMO151: {
      id_os: "Rejection reason - 1",
      id_poor_finish: "Rejection reason - 2",
      id_unwash: "Rejection reason - 3",
      id_blow_hole: "Rejection reason - 4",
      width_us_os: "Rejection reason - 5",
      id_perpendicularity_issue: "Rejection reason - 6",
      more_chamfer: "Rejection reason - 7",
      less_chamfer: "Rejection reason - 8",
      power_failure: "Rejection reason - 9",
      settings_rejection: "Rejection reason - 10",
    },
    
    AMO152:{
      id_os: "Rejection reason - 1",
      id_poor_finish: "Rejection reason - 2",
      id_unwash: "Rejection reason - 3",
      id_blow_hole: "Rejection reason - 4",
      width_us_os: "Rejection reason - 5",
      id_perpendicularity_issue: "Rejection reason - 6",
      more_chamfer: "Rejection reason - 7",
      less_chamfer: "Rejection reason - 8",
      power_failure: "Rejection reason - 9",
      settings_rejection: "Rejection reason - 10",
    },
  
    AMO153: {
      id_os: "Rejection reason - 1",
      id_poor_finish: "Rejection reason - 2",
      id_unwash: "Rejection reason - 3",
      id_blow_hole: "Rejection reason - 4",
      width_us_os: "Rejection reason - 5",
      id_perpendicularity_issue: "Rejection reason - 6",
      more_chamfer: "Rejection reason - 7",
      less_chamfer: "Rejection reason - 8",
      power_failure: "Rejection reason - 9",
      settings_rejection: "Rejection reason - 10",
    },
  
    AMO154: {
      id_os: "Rejection reason - 1",
      id_poor_finish: "Rejection reason - 2",
      id_unwash: "Rejection reason - 3",
      id_blow_hole: "Rejection reason - 4",
      width_us_os: "Rejection reason - 5",
      id_perpendicularity_issue: "Rejection reason - 6",
      more_chamfer: "Rejection reason - 7",
      less_chamfer: "Rejection reason - 8",
      power_failure: "Rejection reason - 9",
      settings_rejection: "Rejection reason - 10",
    },
  };
  

  