export const rejectionData = {
    "success": true,
    "rejectionTrend": [
        {
            "date_of_job": "01/07/2021",
            "s1_rejection": 361,
            "s2_rejection": 259,
            "daily_rejection": 620
        },
        {
            "date_of_job": "02/07/2021",
            "s1_rejection": 412,
            "s2_rejection": 191,
            "daily_rejection": 603
        },
        {
            "date_of_job": "03/07/2021",
            "s1_rejection": 435,
            "s2_rejection": 286,
            "daily_rejection": 721
        },
        {
            "date_of_job": "05/07/2021",
            "s1_rejection": 179,
            "s2_rejection": 422,
            "daily_rejection": 601
        },
        {
            "date_of_job": "06/07/2021",
            "s1_rejection": 273,
            "s2_rejection": 544,
            "daily_rejection": 817
        },
        {
            "date_of_job": "07/07/2021",
            "s1_rejection": 454,
            "s2_rejection": 521,
            "daily_rejection": 975
        },
        {
            "date_of_job": "08/07/2021",
            "s1_rejection": 159,
            "s2_rejection": 318,
            "daily_rejection": 477
        },
        {
            "date_of_job": "09/07/2021",
            "s1_rejection": 408,
            "s2_rejection": 285,
            "daily_rejection": 693
        },
        {
            "date_of_job": "10/07/2021",
            "s1_rejection": 323,
            "s2_rejection": 339,
            "daily_rejection": 662
        },
        {
            "date_of_job": "12/07/2021",
            "s1_rejection": 429,
            "s2_rejection": 187,
            "daily_rejection": 616
        },
        {
            "date_of_job": "13/07/2021",
            "s1_rejection": 157,
            "s2_rejection": 238,
            "daily_rejection": 395
        },
        {
            "date_of_job": "14/07/2021",
            "s1_rejection": 482,
            "s2_rejection": 201,
            "daily_rejection": 683
        },
        {
            "date_of_job": "15/07/2021",
            "s1_rejection": 273,
            "s2_rejection": 343,
            "daily_rejection": 616
        },
        {
            "date_of_job": "16/07/2021",
            "s1_rejection": 227,
            "s2_rejection": 136,
            "daily_rejection": 363
        },
        {
            "date_of_job": "17/07/2021",
            "s1_rejection": 396,
            "s2_rejection": 224,
            "daily_rejection": 620
        },
        {
            "date_of_job": "19/07/2021",
            "s1_rejection": 163,
            "s2_rejection": 657,
            "daily_rejection": 820
        },
        {
            "date_of_job": "20/07/2021",
            "s1_rejection": 163,
            "s2_rejection": 467,
            "daily_rejection": 630
        },
        {
            "date_of_job": "21/07/2021",
            "s1_rejection": 97,
            "s2_rejection": 496,
            "daily_rejection": 593
        },
        {
            "date_of_job": "22/07/2021",
            "s1_rejection": 241,
            "s2_rejection": 281,
            "daily_rejection": 522
        },
        {
            "date_of_job": "23/07/2021",
            "s1_rejection": 224,
            "s2_rejection": 476,
            "daily_rejection": 700
        },
        {
            "date_of_job": "24/07/2021",
            "s1_rejection": 146,
            "s2_rejection": 471,
            "daily_rejection": 617
        },
        {
            "date_of_job": "25/07/2021",
            "s1_rejection": 288,
            "s2_rejection": 424,
            "daily_rejection": 712
        },
        {
            "date_of_job": "26/07/2021",
            "s1_rejection": 252,
            "s2_rejection": 434,
            "daily_rejection": 686
        },
        {
            "date_of_job": "27/07/2021",
            "s1_rejection": 251,
            "s2_rejection": 658,
            "daily_rejection": 909
        },
        {
            "date_of_job": "28/07/2021",
            "s1_rejection": 361,
            "s2_rejection": 590,
            "daily_rejection": 951
        },
        {
            "date_of_job": "29/07/2021",
            "s1_rejection": 274,
            "s2_rejection": 533,
            "daily_rejection": 807
        },
        {
            "date_of_job": "30/07/2021",
            "s1_rejection": 315,
            "s2_rejection": 321,
            "daily_rejection": 636
        }
    ],
    "rejectionData": {
        "monthlyData": {
            "OP110": {
                "shift1": {
                    "id_os": 3,
                    "id_poor_finish": 12,
                    "id_unwash": 87,
                    "id_blow_hole": 111,
                    "width_us_os": 56,
                    "id_perpendicularity_issue": 232,
                    "more_chamfer": 21,
                    "less_chamfer": 0,
                    "power_failure": 10,
                    "settings_rejection": 3,
                    "total_rejection": 468
                },
                "daily": {
                    "id_os": 39,
                    "id_poor_finish": 79,
                    "id_unwash": 95,
                    "id_blow_hole": 275,
                    "width_us_os": 125,
                    "id_perpendicularity_issue": 404,
                    "more_chamfer": 21,
                    "less_chamfer": 0,
                    "power_failure": 20,
                    "settings_rejection": 3,
                    "total_rejection": 994
                },
                "shift2": {
                    "id_os": 36,
                    "id_poor_finish": 67,
                    "id_unwash": 8,
                    "id_blow_hole": 164,
                    "width_us_os": 69,
                    "id_perpendicularity_issue": 172,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 10,
                    "settings_rejection": 0,
                    "total_rejection": 526
                }
            },
            "OP130": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 28,
                    "id_unwash": 344,
                    "id_blow_hole": 592,
                    "width_us_os": 1502,
                    "id_perpendicularity_issue": 1664,
                    "more_chamfer": 16,
                    "less_chamfer": 93,
                    "power_failure": 9,
                    "settings_rejection": 172,
                    "total_rejection": 4420
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 52,
                    "id_unwash": 1292,
                    "id_blow_hole": 1384,
                    "width_us_os": 3258,
                    "id_perpendicularity_issue": 4517,
                    "more_chamfer": 24,
                    "less_chamfer": 101,
                    "power_failure": 9,
                    "settings_rejection": 648,
                    "total_rejection": 11285
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 24,
                    "id_unwash": 948,
                    "id_blow_hole": 792,
                    "width_us_os": 1756,
                    "id_perpendicularity_issue": 2853,
                    "more_chamfer": 8,
                    "less_chamfer": 8,
                    "power_failure": 0,
                    "settings_rejection": 476,
                    "total_rejection": 6865
                }
            },
            "OP140": {
                "shift1": {
                    "id_os": 365,
                    "id_poor_finish": 715,
                    "id_unwash": 28,
                    "id_blow_hole": 16,
                    "width_us_os": 912,
                    "id_perpendicularity_issue": 149,
                    "more_chamfer": 40,
                    "less_chamfer": 503,
                    "power_failure": 0,
                    "settings_rejection": 17,
                    "total_rejection": 2745
                },
                "daily": {
                    "id_os": 684,
                    "id_poor_finish": 1413,
                    "id_unwash": 75,
                    "id_blow_hole": 208,
                    "width_us_os": 1823,
                    "id_perpendicularity_issue": 332,
                    "more_chamfer": 101,
                    "less_chamfer": 716,
                    "power_failure": 0,
                    "settings_rejection": 204,
                    "total_rejection": 5556
                },
                "shift2": {
                    "id_os": 319,
                    "id_poor_finish": 698,
                    "id_unwash": 47,
                    "id_blow_hole": 192,
                    "width_us_os": 911,
                    "id_perpendicularity_issue": 183,
                    "more_chamfer": 61,
                    "less_chamfer": 213,
                    "power_failure": 0,
                    "settings_rejection": 187,
                    "total_rejection": 2811
                }
            },
            " SELE": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 17,
                    "id_unwash": 12,
                    "id_blow_hole": 29,
                    "width_us_os": 17,
                    "id_perpendicularity_issue": 19,
                    "more_chamfer": 0,
                    "less_chamfer": 22,
                    "power_failure": 7,
                    "settings_rejection": 0,
                    "total_rejection": 110
                },
                "daily": {
                    "id_os": 9,
                    "id_poor_finish": 32,
                    "id_unwash": 13,
                    "id_blow_hole": 29,
                    "width_us_os": 38,
                    "id_perpendicularity_issue": 44,
                    "more_chamfer": 4,
                    "less_chamfer": 47,
                    "power_failure": 7,
                    "settings_rejection": 0,
                    "total_rejection": 210
                },
                "shift2": {
                    "id_os": 9,
                    "id_poor_finish": 15,
                    "id_unwash": 1,
                    "id_blow_hole": 0,
                    "width_us_os": 21,
                    "id_perpendicularity_issue": 25,
                    "more_chamfer": 4,
                    "less_chamfer": 25,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 100
                }
            },
            "OP115": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                }
            },
            "": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                }
            }
        },
        "dailyData": {
            "OP130": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 7,
                    "width_us_os": 106,
                    "id_perpendicularity_issue": 33,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 146
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 13,
                    "width_us_os": 181,
                    "id_perpendicularity_issue": 48,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 242
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 6,
                    "width_us_os": 75,
                    "id_perpendicularity_issue": 15,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 96
                }
            },
            " SELE": {
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 3,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 3
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 6,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 6
                },
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 3,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 3
                }
            },
            "OP110": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 2,
                    "width_us_os": 7,
                    "id_perpendicularity_issue": 18,
                    "more_chamfer": 2,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 29
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 6,
                    "width_us_os": 7,
                    "id_perpendicularity_issue": 21,
                    "more_chamfer": 2,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 36
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 4,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 3,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 7
                }
            },
            "OP140": {
                "shift1": {
                    "id_os": 4,
                    "id_poor_finish": 10,
                    "id_unwash": 10,
                    "id_blow_hole": 0,
                    "width_us_os": 45,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 10,
                    "less_chamfer": 16,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 95
                },
                "daily": {
                    "id_os": 8,
                    "id_poor_finish": 15,
                    "id_unwash": 10,
                    "id_blow_hole": 0,
                    "width_us_os": 273,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 10,
                    "less_chamfer": 16,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 332
                },
                "shift2": {
                    "id_os": 4,
                    "id_poor_finish": 5,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 228,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 237
                }
            }
        }
    }
}
