import React from "react";
//import dayjs from "dayjs";
import { 
  //Button, 
  Typography } from "@material-ui/core";
//import { useConfirm } from "material-ui-confirm";
//import { useSnackbar } from "notistack";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { useStyles } from "./styles";

const PublicModels = () =>{
  const classes = useStyles();
  //const confirmPopup = useConfirm();
  //const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h3" >
            Manage Models
          </Typography>
          <div className={classes.subTitleContainer}>
            {/* <Typography variant="h6" className={classes.subTitle}>
              Dasboard
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} /> */}
            <Typography variant="h6" className={classes.subTitle}>
              X Hub
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} />
            <Typography variant="h6" className={classes.subTitle}>
              Models
            </Typography>
          </div>
        </div>
        {/* <div className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.submit}
            //onClick={}
            disableFocusRipple
          >
            + New Model
          </Button>
        </div> */}
      </div>
    </>
  );
}

export default React.memo(PublicModels);
