import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  name: {
    color: theme.palette.secondary.contrastText,
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    width: "100%",
  },
  userBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    alignItems: "center",
    width: "100%",
    flexGrow: 0,
    marginBottom: "1rem"
  },
  avatar: {
    //margin: theme.spacing(1),
    height: "40px",
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#fefefe",
    color: theme.palette.primary.dark,
    fontSize: "1.25rem"
  },
  profileText:{
    cursor: "pointer",
    margin: theme.spacing(1),
    borderRadius: "50%",
    border: "2px solid white",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  profile: {
    cursor: "pointer",
    margin: theme.spacing(1),
    borderRadius: "50%",
    //border: "2px solid white",
    padding: theme.spacing(1),
    //backgroundColor: theme.palette.secondary.main,
  },
  modalButton: {
    alignSelf: "flex-end",
    maxWidth: "150px",
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    width: "100%",
    marginBottom: 0,
  },
  modalBody: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: 500,
    height: 700,
    backgroundColor: theme.palette.background.paper,
    //boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 2),
    borderRadius: "10px"
  },

}));

