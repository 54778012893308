import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 200;

export const useStyles = makeStyles((theme) => ({
  root:{
    display: "flex",
    height: "100%",
    "& .MuiListItem-gutters":{
      paddingLeft: 0
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
    "& .MuiListItem-button:hover": {
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.dark
      },
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.dark
    },
    "& .MuiListItem-divider": {
      border: "none",
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    display: "flex",
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: theme.palette.primary.main,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(12) + 1,
    },
  },
  toolbar: {
    position:"relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    width:"100%"
  },
  content: {
    flexGrow: 1,
    height: "100%",
    padding: theme.spacing(3),
    overflowY: "auto"
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    position: "relative",
    height:"100%",
    display: "flex",
    flexDirection: "column",
    overflowX:"hidden",
  },
  list: {
    height: "100%", 
  },
  listItem: {
    display: "flex",
    borderRadius :"5px",
    marginTop: "5px",
    color: "#fff",
    "& .MuiSvgIcon-root":{
      color: "#fff",
    }
  },
  listItemIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "normal",
    minWidth: "48px",
  },
  listItemIconClose:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    minWidth: "48px",
    marginLeft: "16px"
  },
  logoBox :{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"2rem",
    marginBottom:"1rem",
  },
  logo:{
    height :"2rem",
    width: "2rem"
  },
  sliderIcon:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  slider :{ 
    position:"absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width:"40px",
    height:"40px",
    color: theme.palette.primary.dark,
    top: 6,
    left: -15,
    borderRadius: "50%",
    cursor:"pointer",
    '&:hover': {
     backgroundColor: "#cecece",
   }
  },
  navBox:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
   },
   navTitle:{
    marginLeft: "0rem",
    color: "#fff",
    marginBottom: "0.5rem",
   },
   navTitleClose:{
    textAlign: "center",
    marginBottom: "0.5rem",
    color: "#fff",
   },
   progressContainer:{
    position:"absolute",
    width: "auto",
    height: "auto",
    right: 0,
    bottom: 0,
    margin: "1rem",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: '10px',
    //border: "2px solid blue",
    justifyContent: "center",
    alignItems: "center",
  }
}));