export const prodInfoData = {
    "success": true,
    "machineInfo": [
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-1",
            "s1_qty": 1037,
            "s2_qty": 1525,
            "daily_qty": 2562,
            "s1_target": 1615,
            "s2_target": 1625,
            "day_target": 3240
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-2",
            "s1_qty": 888,
            "s2_qty": 401,
            "daily_qty": 1289,
            "s1_target": 895,
            "s2_target": 951,
            "day_target": 1846
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-3",
            "s1_qty": 811,
            "s2_qty": 750,
            "daily_qty": 1561,
            "s1_target": 926,
            "s2_target": 1000,
            "day_target": 1926
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-4",
            "s1_qty": 1300,
            "s2_qty": 1580,
            "daily_qty": 2880,
            "s1_target": 1236,
            "s2_target": 1575,
            "day_target": 2811
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-5",
            "s1_qty": 1430,
            "s2_qty": 1450,
            "daily_qty": 2880,
            "s1_target": 1560,
            "s2_target": 1625,
            "day_target": 3185
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-6",
            "s1_qty": 1016,
            "s2_qty": 1032,
            "daily_qty": 2048,
            "s1_target": 1041,
            "s2_target": 1083,
            "day_target": 2124
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-7",
            "s1_qty": 1352,
            "s2_qty": 1352,
            "daily_qty": 2704,
            "s1_target": 1493,
            "s2_target": 1500,
            "day_target": 2993
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-8",
            "s1_qty": 1530,
            "s2_qty": 1450,
            "daily_qty": 2980,
            "s1_target": 1538,
            "s2_target": 1464,
            "day_target": 3002
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-9",
            "s1_qty": 232,
            "s2_qty": 1424,
            "daily_qty": 1656,
            "s1_target": 1317,
            "s2_target": 1416,
            "day_target": 2733
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-10",
            "s1_qty": 1444,
            "s2_qty": 1452,
            "daily_qty": 2896,
            "s1_target": 1485,
            "s2_target": 1560,
            "day_target": 3045
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-11",
            "s1_qty": 1430,
            "s2_qty": 1450,
            "daily_qty": 2880,
            "s1_target": 1560,
            "s2_target": 1625,
            "day_target": 3185
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-12",
            "s1_qty": 1430,
            "s2_qty": 1450,
            "daily_qty": 2880,
            "s1_target": 1560,
            "s2_target": 1625,
            "day_target": 3185
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-13",
            "s1_qty": 1460,
            "s2_qty": 1486,
            "daily_qty": 2946,
            "s1_target": 1575,
            "s2_target": 1575,
            "day_target": 3150
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-14",
            "s1_qty": 1100,
            "s2_qty": 1002,
            "daily_qty": 2102,
            "s1_target": 1575,
            "s2_target": 1575,
            "day_target": 3150
        },
        {
            "date_of_job": "1/7/2021",
            "machine": "Machine-15",
            "s1_qty": 1411,
            "s2_qty": 1475,
            "daily_qty": 2886,
            "s1_target": 1575,
            "s2_target": 1575,
            "day_target": 3150
        },

    ],
    "machineShopInfo": {
        "BHADRA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS639",
                    "NA"
                ],
                "operator": [
                    "AMAN_DPS",
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "MANJUNAT",
                    "NOT SELE"
                ],
                "supervisor": [
                    "AJAY_DPS",
                    "NOT SELE"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS595"
                ],
                "operator": [
                    "BINOD_DP"
                ],
                "line_inspector": [
                    "SANTHOSH"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "CHATURBH": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS182",
                    "NA"
                ],
                "operator": [
                    "MAHADEV_",
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "MANJUNAT",
                    "NOT SELE"
                ],
                "supervisor": [
                    "AJAY_DPS",
                    "NOT SELE"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS130"
                ],
                "operator": [
                    "MOHAN_DP"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "DURGA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS609"
                ],
                "operator": [
                    "AMBRISH_"
                ],
                "line_inspector": [
                    "NOT SELE"
                ],
                "supervisor": [
                    "DAYANAND"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS556"
                ],
                "operator": [
                    "PRAFULLA"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "ABHILASH"
                ]
            }
        },
        "GOWRI": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS627"
                ],
                "operator": [
                    "GURUNATH"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS621"
                ],
                "operator": [
                    "KUSHAL_D"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "HARA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS590"
                ],
                "operator": [
                    "NITHIN_D"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "NA"
                ],
                "operator": [
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "NOT SELE"
                ],
                "supervisor": [
                    "NOT SELE"
                ]
            }
        },
        "HARI": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS456"
                ],
                "operator": [
                    "SURJYA_D"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS297",
                    "NA"
                ],
                "operator": [
                    "BASAVARA",
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "VISHWAS_",
                    "NOT SELE"
                ],
                "supervisor": [
                    "NIVESH_D",
                    "NOT SELE"
                ]
            }
        },
        "HAYAGREE": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS067",
                    "DPS426"
                ],
                "operator": [
                    "ABHILASH",
                    "ABHISHEK"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "HALESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS426",
                    "DPS474"
                ],
                "operator": [
                    "ABHISHEK",
                    "PUNITH_D"
                ],
                "line_inspector": [
                    "MANJUNAT",
                    "VISHWAS_"
                ],
                "supervisor": [
                    "HALESH_D",
                    "MEGHARAJ"
                ]
            }
        },
        "HRISHIKE": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS193"
                ],
                "operator": [
                    "GORGESHW"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "HALESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS583"
                ],
                "operator": [
                    "SHAILEND"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "KAVERI": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "NA"
                ],
                "operator": [
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "NOT SELE"
                ],
                "supervisor": [
                    "NOT SELE"
                ]
            },
            "shift2": {
                "operator_id": [
                    "NA"
                ],
                "operator": [
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "NOT SELE"
                ],
                "supervisor": [
                    "NOT SELE"
                ]
            }
        },
        "KRISHNA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS513"
                ],
                "operator": [
                    "MANTU_DP"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS115"
                ],
                "operator": [
                    "MANJUNAT"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            }
        },
        "LAKSHMAN": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS582"
                ],
                "operator": [
                    "PADUM_DP"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "HALESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS333"
                ],
                "operator": [
                    "VEERESHA"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "MEGHARAJ"
                ]
            }
        },
        "LAKSHMI": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS634"
                ],
                "operator": [
                    "DILIP_DP"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "ABHILASH"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS10021",
                    "DPS634"
                ],
                "operator": [
                    "AMAR_DPS",
                    "DILIP_DP"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "ABHILASH"
                ]
            }
        },
        "MADHAVA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS586"
                ],
                "operator": [
                    "KRISHNA_"
                ],
                "line_inspector": [
                    "MANJUNAT",
                    "NITHIN_D"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS334"
                ],
                "operator": [
                    "VENKATAC"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "NARASIMH": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS414"
                ],
                "operator": [
                    "BISWAJIT"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS361"
                ],
                "operator": [
                    "RATAN_DP"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "NARAYANA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS464"
                ],
                "operator": [
                    "GAUTAM_D"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS299"
                ],
                "operator": [
                    "SANTHOSH"
                ],
                "line_inspector": [
                    "SANTHOSH"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "RAMA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS639",
                    "NA"
                ],
                "operator": [
                    "AMAN_DPS",
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "HALESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS518"
                ],
                "operator": [
                    "ABUL_DPS"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "MEGHARAJ"
                ]
            }
        },
        "SARASWAT": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS622"
                ],
                "operator": [
                    "LABA_DPS"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "ABHILASH"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS10021"
                ],
                "operator": [
                    "AMAR_DPS"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "ABHILASH"
                ]
            }
        },
        "SHARVA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS564",
                    "DPS517"
                ],
                "operator": [
                    "BHIMARAV",
                    "MDMAZHAR"
                ],
                "line_inspector": [
                    "VISHWAS_",
                    "MANJUNAT"
                ],
                "supervisor": [
                    "MEGHARAJ",
                    "HALESH_D"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS564"
                ],
                "operator": [
                    "BHIMARAV"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "MEGHARAJ"
                ]
            }
        },
        "TUNGA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "DPS628"
                ],
                "operator": [
                    "GUNA_DPS"
                ],
                "line_inspector": [
                    "MANJUNAT"
                ],
                "supervisor": [
                    "AJAY_DPS"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS633"
                ],
                "operator": [
                    "RAJESH_D"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        },
        "VAMANA": {
            "date": "2021-07-01",
            "shift1": {
                "operator_id": [
                    "NA"
                ],
                "operator": [
                    "NO_OPERA"
                ],
                "line_inspector": [
                    "NOT SELE"
                ],
                "supervisor": [
                    "NOT SELE"
                ]
            },
            "shift2": {
                "operator_id": [
                    "DPS516",
                    "NA"
                ],
                "operator": [
                    "AADIL_DP",
                    "DPS"
                ],
                "line_inspector": [
                    "VISHWAS_"
                ],
                "supervisor": [
                    "NIVESH_D"
                ]
            }
        }
    }
}