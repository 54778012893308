import React from "react";
import clsx from "clsx";
import {Link, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from "@material-ui/icons/Menu";
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import CategoryIcon from '@material-ui/icons/Category';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import SettingsIcon from '@material-ui/icons/Settings';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import Jobs from "../Jobs/Jobs";
import Models from "../Models/Models";
import Datasets from "../Datasets/Datasets";
import Teams from "../Teams/Teams";
import PublicModels from "../public/Models/PublicModels";
import PublicDatasets from "../public/Datasets/PublicDatasets";

import UserProfile from "../../components/UserProfile/UserProfile";

import { useStyles } from "./styles";

const menuItems = [
  {
    category:"workspace",
    path: "/dashboard/",
    text: "Main Dashboard",
    renderIcon: () => {
      return <ViewModuleIcon  />;
    },
  },
  {
    category:"workspace",
    path: "/dashboard/productionreport",
    text: "Production Report",
    renderIcon: () => {
      return <StorageIcon   />;
    },
  },
  {
    category:"workspace",
    path: "/dashboard/idletimereport",
    text: "Idletime & Rejection",
    renderIcon: () => {
      return <AlarmOnIcon  />;
    },
  },
  {
    category:"workspace",
    path: "/dashboard/machinereport",
    text: "Machines Report",
    renderIcon: () => {
      return <PeopleIcon   />;
    },
  },
  {
    category:"XHub",
    path: "/dashboard/XHub/models",
    text: "Models",
    renderIcon: () => {
      return  <CategoryIcon   />;
    },
  },
  {
    category:"XHub",
    path: "/dashboard/machinereport",
    text: "Datasets",
    renderIcon: () => {
      return <StorageIcon  />;
    },
  },
];

const Dashboard = () => {
  const classes = useStyles();
 
  const { url:activeUrl }=useRouteMatch();
  const [open, setOpen] = React.useState(true);


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.logoBox}>
            <Link to="/dashboard/">
              <SettingsIcon style={{color: "#fff"}}/>
            </Link>
            <Typography
              variant="h4"
              style={{
                display: open ? "" : "none",
                marginLeft: "5px",
                color: "#fff"
              }}
            >
              AMIGO ERP
            </Typography>
          </div>
          <div className={classes.navBox}>
            <List className={classes.list}>
                {/* <Typography className={open ? classes.navTitle : classes.navTitleClose} variant="body1">
                  Workspace
                </Typography> */}
                {menuItems
                  .filter((item)=> item.category === "workspace")
                  .map((menuItem) => {
                  const tabLabel = menuItem.text;
                  const path = menuItem.path;
                  return (
                    <Link to={path} key={tabLabel}>
                      <ListItem
                        button
                        divider
                        id={tabLabel.toLowerCase()}
                        className={classes.listItem}
                        selected={path === activeUrl}
                      >
                        <Tooltip title={tabLabel} enterTouchDelay={200}>
                          <ListItemIcon
                            className={
                              open
                                ? classes.listItemIcon
                                : classes.listItemIconClose
                            }
                          >
                            {menuItem.renderIcon()}
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                          style={{
                            display: !open ? "none" : "",
                          }}
                          primary={tabLabel}
                        />
                      </ListItem>
                    </Link>
                  );
                })}
            </List>
          </div>
          <div
            className={classes.navBox}
            style={{
              width: "100%",
              bottom: "1rem",
              marginTop: "auto"
            }}
          >
            <UserProfile isOpen={open} />
          </div>
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <div className={classes.slider}>
              <MenuIcon onClick={() => setOpen((prevOpen) => !prevOpen)} />
          </div>
            <Switch>
              <Route exact path={`/dashboard/`}>
              <Datasets />
              </Route>
              <Route exact path={`/dashboard/productionreport`}>
                <Jobs />
              </Route>
              <Route exact path={`/dashboard/idletimereport`}>
               <Models />
              </Route>
              <Route exact path={`/dashboard/machinereport`}>
               <PublicDatasets />
              </Route>
              <Route exact path={`/dashboard/XHub/models`}>
                <PublicModels />
              </Route>
              <Route exact path={`/dashboard/XHub/datasets`}>
                <PublicDatasets />
              </Route>
            </Switch>
        </div> 
      </main>
    </div>
  );
}

export default Dashboard;
