import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import GlobalStyles from "./components/GlobalStyles";
import theme from "./theme/theme";
import MainLayout from "./components/layout/MainLayout";
import NotFound from "./components/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";

import "./App.css";


const App = () => {
  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={() =><Dashboard/>}
            />
            <Route
              exact
              path="/dashboard/*"
              render={() => <MainLayout children={() => <Dashboard/>} />}
            /> 
            <Route path="*" component={() => <NotFound />} />
          </Switch>
        </Router>
    </ThemeProvider>
  );
};

export default App;
