import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageContainer : {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width:"100%",
    padding: "0px 24px 0px 24px",
   },
  titleContainer:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems:"centetr",
    padding:"10px 0 10px 0",
   },
  subTitleContainer:{
    display: "flex",
    justifyContent: "left",
    marginTop:"0.5rem",
    alignItems:"center"
  },
  subTitle:{
    marginRight: "5px",
    //fontWeight: "bold",
    color: theme.palette.primary.light,
  },
  subTitleIcon:
  { 
    marginRight: "5px",
    fontSize:"0.8rem",
    color: theme.palette.primary.light,
  },
  tableContainer:{
    display: "flex",
    width:"100%"
  },
  btnContainer:{
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    margin : 0,
  },
  dataGrid: {
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    borderRadius: 16,
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
  },
  tabContainer: {
    backgroundColor: theme.palette.common.white,
    alignSelf:"flex-end",
    width: "fit-content",
    borderRadius:"10px",
    //marginTop:"20px",
    //margin: "0 auto",
    "& button.MuiButtonBase-root": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
    "& button.MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      //borderBottom: "3px solid #0e4378",
    },
    "& button.MuiButtonBase-root.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "& button.MuiButtonBase-root:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  singletab:{
    fontWeight: "600", 
    borderRadius:"10px",
  },
  submit: {
    maxWidth: "200px",
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary.contrastText,
    //backgroundColor: theme.palette.primary.main,
    backgroundColor:"#0e4378",
    marginBottom : 0,
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
  modalButton: {
    alignSelf: "flex-end",
    maxWidth: "150px",
    margin: "0 auto",
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    "&.MuiButtonBase-root.Mui-disabled": {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
    width: "100%",
    marginBottom: 0,
  },
  modalBody: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    width: 600,
    height: 600,
    margin: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 2),
  },
}));
