export const idleTimeData = {
    "success": true,
    "idleTimeTrend": [
        {
            "date_of_job": "01/07/2021",
            "s1_idletime": 4877,
            "s2_idletime": 4280,
            "daily_idletime": 9157
        },
        {
            "date_of_job": "02/07/2021",
            "s1_idletime": 4440,
            "s2_idletime": 4579,
            "daily_idletime": 9019
        },
        {
            "date_of_job": "03/07/2021",
            "s1_idletime": 4733,
            "s2_idletime": 3377,
            "daily_idletime": 8110
        },
        {
            "date_of_job": "05/07/2021",
            "s1_idletime": 3144,
            "s2_idletime": 3894,
            "daily_idletime": 7038
        },
        {
            "date_of_job": "06/07/2021",
            "s1_idletime": 5863,
            "s2_idletime": 4466,
            "daily_idletime": 10329
        },
        {
            "date_of_job": "07/07/2021",
            "s1_idletime": 4399,
            "s2_idletime": 4737,
            "daily_idletime": 9136
        },
        {
            "date_of_job": "08/07/2021",
            "s1_idletime": 4201,
            "s2_idletime": 6715,
            "daily_idletime": 10916
        },
        {
            "date_of_job": "09/07/2021",
            "s1_idletime": 5671,
            "s2_idletime": 5741,
            "daily_idletime": 11412
        },
        {
            "date_of_job": "10/07/2021",
            "s1_idletime": 5899,
            "s2_idletime": 1759,
            "daily_idletime": 7658
        },
        {
            "date_of_job": "12/07/2021",
            "s1_idletime": 5152,
            "s2_idletime": 3967,
            "daily_idletime": 9119
        },
        {
            "date_of_job": "13/07/2021",
            "s1_idletime": 5452,
            "s2_idletime": 5385,
            "daily_idletime": 10837
        },
        {
            "date_of_job": "14/07/2021",
            "s1_idletime": 3881,
            "s2_idletime": 3010,
            "daily_idletime": 6891
        },
        {
            "date_of_job": "15/07/2021",
            "s1_idletime": 5277,
            "s2_idletime": 4631,
            "daily_idletime": 9908
        },
        {
            "date_of_job": "16/07/2021",
            "s1_idletime": 4829,
            "s2_idletime": 4304,
            "daily_idletime": 9133
        },
        {
            "date_of_job": "17/07/2021",
            "s1_idletime": 5909,
            "s2_idletime": 5788,
            "daily_idletime": 11697
        },
        {
            "date_of_job": "19/07/2021",
            "s1_idletime": 5106,
            "s2_idletime": 4082,
            "daily_idletime": 9188
        },
        {
            "date_of_job": "20/07/2021",
            "s1_idletime": 4093,
            "s2_idletime": 3126,
            "daily_idletime": 7219
        },
        {
            "date_of_job": "21/07/2021",
            "s1_idletime": 3472,
            "s2_idletime": 3694,
            "daily_idletime": 7166
        },
        {
            "date_of_job": "22/07/2021",
            "s1_idletime": 2997,
            "s2_idletime": 3425,
            "daily_idletime": 6422
        },
        {
            "date_of_job": "23/07/2021",
            "s1_idletime": 3632,
            "s2_idletime": 3059,
            "daily_idletime": 6691
        },
        {
            "date_of_job": "24/07/2021",
            "s1_idletime": 3185,
            "s2_idletime": 2262,
            "daily_idletime": 5447
        },
        {
            "date_of_job": "25/07/2021",
            "s1_idletime": 3778,
            "s2_idletime": 3947,
            "daily_idletime": 7725
        },
        {
            "date_of_job": "26/07/2021",
            "s1_idletime": 2829,
            "s2_idletime": 3519,
            "daily_idletime": 6348
        },
        {
            "date_of_job": "27/07/2021",
            "s1_idletime": 3146,
            "s2_idletime": 3533,
            "daily_idletime": 6679
        },
        {
            "date_of_job": "28/07/2021",
            "s1_idletime": 3366,
            "s2_idletime": 4650,
            "daily_idletime": 8016
        },
        {
            "date_of_job": "29/07/2021",
            "s1_idletime": 3127,
            "s2_idletime": 2564,
            "daily_idletime": 5691
        },
        {
            "date_of_job": "30/07/2021",
            "s1_idletime": 4755,
            "s2_idletime": 2155,
            "daily_idletime": 6910
        }
    ],
    "idleTimeData": {
        "monthlyData": {
            "shift1": {
                "no_load": 33674,
                "no_operator": 28658,
                "no_tool": 3,
                "no_power": 8083,
                "tool_change": 727,
                "under_setting": 5101,
                "under_maintenance": 2579,
                "breakfast_snack": 8826,
                "lunch_dinner": 13105,
                "tea_break": 7053,
                "machine_cleaning": 5499,
                "other": 4342,
                "total_idletime": 117213
            },
            "shift2": {
                "no_load": 28559,
                "no_operator": 33811,
                "no_tool": 0,
                "no_power": 1591,
                "tool_change": 153,
                "under_setting": 2590,
                "under_maintenance": 4260,
                "breakfast_snack": 3981,
                "lunch_dinner": 12338,
                "tea_break": 4883,
                "machine_cleaning": 8670,
                "other": 6243,
                "total_idletime": 106649
            },
            "daily": {
                "no_load": 62233,
                "no_operator": 62469,
                "no_tool": 3,
                "no_power": 9674,
                "tool_change": 880,
                "under_setting": 7691,
                "under_maintenance": 6839,
                "breakfast_snack": 12807,
                "lunch_dinner": 25443,
                "tea_break": 11936,
                "machine_cleaning": 14169,
                "other": 10585,
                "total_idletime": 223862
            }
        },
        "dailyData": {
            "shift1": {
                "no_load": 894,
                "no_operator": 2048,
                "no_tool": 0,
                "no_power": 614,
                "tool_change": 0,
                "under_setting": 50,
                "under_maintenance": 117,
                "breakfast_snack": 352,
                "lunch_dinner": 516,
                "tea_break": 226,
                "machine_cleaning": 288,
                "other": 191,
                "total_idletime": 5277
            },
            "shift2": {
                "no_load": 1125,
                "no_operator": 1901,
                "no_tool": 0,
                "no_power": 0,
                "tool_change": 2,
                "under_setting": 92,
                "under_maintenance": 105,
                "breakfast_snack": 137,
                "lunch_dinner": 502,
                "tea_break": 173,
                "machine_cleaning": 437,
                "other": 174,
                "total_idletime": 4631
            },
            "daily": {
                "no_load": 2019,
                "no_operator": 3949,
                "no_tool": 0,
                "no_power": 614,
                "tool_change": 2,
                "under_setting": 142,
                "under_maintenance": 222,
                "breakfast_snack": 489,
                "lunch_dinner": 1018,
                "tea_break": 399,
                "machine_cleaning": 725,
                "other": 365,
                "total_idletime": 9908
            }
        }
    }
}