import React from "react";
import { Typography } from "@material-ui/core";
import BarChart from "../../components/Charts/BarChart/BarChart";
import IdleTimeCard from "../../components/IdleTimeDoughnut/IdleTimeCard";
import RejectionCard from "../../components/RejectionDoughnut/RejectionCard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles } from "./styles";
import { dasboardData } from "./data";
import { prodInfoData } from "./data2";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant";

const chartTitle2 = prodInfoData["machineInfo"]
    ? `Machinewise Production Information(For the selected Day)`
    : "";

  const chartLabels2 = prodInfoData["machineInfo"]
    ? prodInfoData["machineInfo"].map((el) => el["machine"])
    : [];

  const dataGroup2 = prodInfoData["machineInfo"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "S1-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s1_qty"]),
          backgroundColor: "#0b84a5",
          borderColor: "#0b84a5",
        },
        {
          ...CHART_UTILS.BAR,
          label: "S1-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s1_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
        {
          ...CHART_UTILS.BAR,
          label: "S2-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s2_qty"]),
          backgroundColor: "#f6c85f",
          borderColor: "#f6c85f",
        },
        {
          label: "S2-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s2_target"]),
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["daily_qty"]),
          backgroundColor: "#df00ff",
          //backgroundColor: "#fa9ba6",
          borderColor: "#df00ff",
        },
        {
          label: "Day-Target",
          data: prodInfoData["machineInfo"].map((el) => el["day_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        }
      ]
    : [];

const chartTitle = dasboardData["oeeTrend"] ? `Overall Equipment Efficiency(For the selected month)` : "";

const chartLabels = dasboardData["oeeTrend"]
  ? dasboardData["oeeTrend"].map((el) => el["date_of_job"])
  : [];

const dataGroup = dasboardData["oeeTrend"]
  ? [
      {
        ...CHART_UTILS.BAR,
        label: "S1",
        data: dasboardData["oeeTrend"].map((el) => el["s1_oee"]),
        backgroundColor: "#0b84a5",
        borderColor: "#0b84a5",
      },
      {
        ...CHART_UTILS.BAR,
        label: "S2",
        data: dasboardData["oeeTrend"].map((el) => el["s2_oee"]),
        backgroundColor: "#f6c85f",
        borderColor: "#f6c85f",
      },
      {
        ...CHART_UTILS.BAR,
        label: "Day",
        data: dasboardData["oeeTrend"].map((el) => el["daily_oee"]),
        backgroundColor: "#fa9ba6",
        borderColor: "#fa465b",
        backgroundColor: "#df00ff",
        borderColor: "#df00ff",
      },
      {
        ...CHART_UTILS.BAR,
        label: "D-Trend",
        data: dasboardData["oeeTrend"].map((el) => el["daily_oee"]),
        pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        type: "line",
        fill: false,
        backgroundColor: "#3A8B9C",
        borderColor: 'rgb(0, 100, 192)',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        // pointStyle: "circle",
        // pointBackgroundColor: "rgba(0, 0, 0, 0)",
        // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
    ]
  : [];

const Datasets = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">Main Dashboard</Typography>
          <div className={classes.subTitleContainer}>
            {/* <Typography variant="h6" className={classes.subTitle}>Dasboard</Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} /> */}
            <Typography variant="h6" className={classes.subTitle}>
              Machine Shop
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} />
            <Typography variant="h6" className={classes.subTitle}>
              Main Dashboard
            </Typography>
          </div>
        </div>
        {/* <div className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.submit}
            //onClick={}
            disableFocusRipple
          >
            + New Dataset
          </Button>
        </div> */}
        <div className="max-container">
          <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={dataGroup}
            //iconClass="fa fa-line-chart"
            xLabel="DAYS"
            yLabel="OEE"
          />
           <BarChart
            chartTitle={chartTitle2}
            chartLabels={chartLabels2}
            dataGroup={dataGroup2}
            //iconClass="fa fa-bar-chart"
            xLabel="DAYS"
            yLabel="ACHIEVED QUANTITY"
          />
          <div className="flex-container">
            <div className="flex-item-2 mb-0">
              {dasboardData["rejectionData"] && (
                <RejectionCard
                  rejectionData={dasboardData["rejectionData"]}
                  rejectionTotal={dasboardData["rejectionIdleTime"]}
                />
              )}
            </div>
            <div className="flex-item-2 mb-0">
              {dasboardData["idleTimeData"] && (
                <IdleTimeCard
                  idleTimeData={dasboardData["idleTimeData"]}
                  idleTimeTotal={dasboardData["rejectionIdleTime"]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Datasets);
