import React from "react";
import ReactDOM from "react-dom";
import BackDrop from "../BackDrop/BackDrop";

import classes from "./Loader.module.css";

const Loader = () => {
  return (
    <>
      {ReactDOM.createPortal(
        <BackDrop>
          <div className={classes.backDrop}>
          <h3 className={classes.loaderTitle}> Solutions</h3>
              {/* <h3 className={classes.loaderTitle}>Distinct Productivity Solutions</h3> */}
            <div className={classes.loaderContainer}>
              <div className={classes.firstSpinner}>
                <i className="fa fa-cog fa-spin" style={{ display: 'block', width: '3.75rem',fontSize: "4rem",color:'#ffc115'}}></i>
              </div>
              <div className={classes.secondSpinner}>
                <i className="fa fa-cog fa-spin" style={{display: 'block', width: '3.75rem',fontSize: "4rem",color:'#ffc115'}}></i>
              </div>
            </div>
          </div>
        </BackDrop>,
        document.getElementById("backdrop-root")
      )}
    </>
  );
};

export default Loader;
