import React from "react";
import { VistaTable } from "vista-table-react";

const OpwiseTable = ({ opData, tableTitle }) => {
  const tableHeader = [
    {key:"op_type", title: "Part Number"},
    {key:"quantity", title: "Achieved"},
    {key:"target", title: "Target"},
    {key:"percentage", title: "Percentage"},
  ];

  const colorObject = {
    rowKey: "op_type",
    colors: {
      OP110: 'tr-1',
      OP130: 'tr-2',
      OP140: 'tr-3'
    },
    length: -5
  }

  return (
    <div className="card-container flex-row border-thin  mt-5">
      <div className="card-header bg-grey">
        <h3>{tableTitle}</h3>
        <span className="cam-icons">
          <i
            className="fa fa-camera-retro ico-lg"
            title="Take a snap"
            // onClick={() => takeASnap()}
          ></i>
        </span>
      </div>
      <div className="card-body pd-0">
        <VistaTable headers={tableHeader} data={opData} color={colorObject} />
      </div>
    </div>
  );
};

export default OpwiseTable;
