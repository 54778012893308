export const machinesData = {
    "success": true,
    "demographyData": [
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-1",
            "achieved_qty": 2950,
            "oee": "79.00",
            "rejection_total": 30,
            "idletime_total": 334,
            "daily_target": 3082
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-2",
            "achieved_qty": 1931,
            "oee": "81.00",
            "rejection_total": 110,
            "idletime_total": 248,
            "daily_target": 1950
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-3",
            "achieved_qty": 2434,
            "oee": "66.50",
            "rejection_total": 0,
            "idletime_total": 525,
            "daily_target": 2627
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-4",
            "achieved_qty": 2120,
            "oee": "59.50",
            "rejection_total": 0,
            "idletime_total": 618,
            "daily_target": 2302
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-5",
            "achieved_qty": 1,
            "oee": "0.00",
            "rejection_total": 0,
            "idletime_total": 1436,
            "daily_target": 2888
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-6",
            "achieved_qty": 958,
            "oee": "39.00",
            "rejection_total": 0,
            "idletime_total": 876,
            "daily_target": 2084
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-7",
            "achieved_qty": 2841,
            "oee": "79.50",
            "rejection_total": 145,
            "idletime_total": 262,
            "daily_target": 2937
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-8",
            "achieved_qty": 2580,
            "oee": "73.00",
            "rejection_total": 131,
            "idletime_total": 281,
            "daily_target": 2937
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-9",
            "achieved_qty": 2416,
            "oee": "67.00",
            "rejection_total": 53,
            "idletime_total": 427,
            "daily_target": 2904
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-10",
            "achieved_qty": 2736,
            "oee": "76.50",
            "rejection_total": 20,
            "idletime_total": 258,
            "daily_target": 3045
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-11",
            "achieved_qty": 2213,
            "oee": "60.50",
            "rejection_total": 0,
            "idletime_total": 471,
            "daily_target": 3177
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-12",
            "achieved_qty": 2872,
            "oee": "78.00",
            "rejection_total": 44,
            "idletime_total": 255,
            "daily_target": 3107
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-13",
            "achieved_qty": 1936,
            "oee": "52.00",
            "rejection_total": 20,
            "idletime_total": 469,
            "daily_target": 3150
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-14",
            "achieved_qty": 2637,
            "oee": "71.50",
            "rejection_total": 3,
            "idletime_total": 357,
            "daily_target": 3150
        },
        {
            "date_of_job": "2021-07-28T00:00:00.000Z",
            "machine": "Machine-15",
            "achieved_qty": 0,
            "oee": "0.00",
            "rejection_total": 0,
            "idletime_total": 1415,
            "daily_target": 3150
        }
    ]
}