import React from "react";
import { Typography } from "@material-ui/core";
import BarChart from "../../components/Charts/BarChart/BarChart";
import TableWrapper from "../../components/TableWrapper/TableWrapper";
import OpwiseTable from "../../components/OpwiseTable/OpwiseTable";
import AchieversTable from "../../components/AchieversTable/AchieversTable";
import IdleTimeCard from "../../components/IdleTimeDoughnut/IdleTimeCard";
import RejectionCard from "../../components/RejectionDoughnut/RejectionCard";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles } from "./styles";
import { dasboardData } from "./data";
import { prodInfoData } from "./data2";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant";

const chartTitle2 = prodInfoData["machineInfo"]
    ? `Machinewise Production Information`
    : "";

  const chartLabels2 = prodInfoData["machineInfo"]
    ? prodInfoData["machineInfo"].map((el) => el["machine"])
    : [];

  const dataGroup2 = prodInfoData["machineInfo"]
    ? [
        {
          ...CHART_UTILS.BAR,
          label: "S1-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s1_qty"]),
          backgroundColor: "#7afade",
          borderColor: "#04b890",
        },
        {
          ...CHART_UTILS.BAR,
          label: "S1-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s1_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
          },
        {
          ...CHART_UTILS.BAR,
          label: "S2-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["s2_qty"]),
          backgroundColor: "#f5ae67",
          borderColor: "#b55d05",
        },
        {
          label: "S2-Target",
          data: prodInfoData["machineInfo"].map((el) => el["s2_target"]),
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day-Achieved",
          data: prodInfoData["machineInfo"].map((el) => el["daily_qty"]),
          backgroundColor: "#fa9ba6",
          borderColor: "#fa465b",
        },
        {
          label: "Day-Target",
          data: prodInfoData["machineInfo"].map((el) => el["day_target"]),
          pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
          type: "line",
          fill: false,
          backgroundColor: "#3A8B9C",
          borderColor: 'rgb(0, 100, 192)',
          borderWidth: 1,
          tension: 0,
          pointRadius: 2,
          // pointStyle: "circle",
          // pointBackgroundColor: "rgba(0, 0, 0, 0)",
          // pointBorderColor: "rgba(0, 0, 0, 0)"
        }
      ]
    : [];

const chartTitle = dasboardData["oeeTrend"] ? `Overall Equipment Efficiency` : "";

const chartLabels = dasboardData["oeeTrend"]
  ? dasboardData["oeeTrend"].map((el) => el["date_of_job"])
  : [];

const dataGroup = dasboardData["oeeTrend"]
  ? [
      {
        ...CHART_UTILS.BAR,
        label: "S1",
        data: dasboardData["oeeTrend"].map((el) => el["s1_oee"]),
        backgroundColor: "#7afade",
        borderColor: "#04b890",
      },
      {
        ...CHART_UTILS.BAR,
        label: "S2",
        data: dasboardData["oeeTrend"].map((el) => el["s2_oee"]),
        backgroundColor: "#f5ae67",
        borderColor: "#b55d05",
      },
      {
        ...CHART_UTILS.BAR,
        label: "Day",
        data: dasboardData["oeeTrend"].map((el) => el["daily_oee"]),
        backgroundColor: "#df00ff",
        borderColor: "#df00ff",
      },
      {
        ...CHART_UTILS.BAR,
        label: "D-Trend",
        data: dasboardData["oeeTrend"].map((el) => el["daily_oee"]),
        pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        type: "line",
        fill: false,
        backgroundColor: "#3A8B9C",
        borderColor: 'rgb(0, 100, 192)',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        // pointStyle: "circle",
        // pointBackgroundColor: "rgba(0, 0, 0, 0)",
        // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
    ]
  : [];

const Jobs = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">Production Report</Typography>
          <div className={classes.subTitleContainer}>
            {/* <Typography variant="h6" className={classes.subTitle}>Dasboard</Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} /> */}
            <Typography variant="h6" className={classes.subTitle}>
              Machine Shop
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} />
            <Typography variant="h6" className={classes.subTitle}>
            Production Report
            </Typography>
          </div>
        </div>
        {/* <div className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.submit}
            //onClick={}
            disableFocusRipple
          >
            + New Dataset
          </Button>
        </div> */}
        <div className="max-container">
          <TableWrapper
            cardTitle="Partwise Production Data"
            //iconClass="fa fa fa-cubes"
          >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dasboardData["prodQtyOpwise"] && (
                  <OpwiseTable
                    opData={dasboardData["prodQtyOpwise"]["monthlyData"]}
                    tableTitle="For the Selected Month"
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dasboardData["prodQtyOpwise"] && (
                  <OpwiseTable
                    opData={dasboardData["prodQtyOpwise"]["dailyData"]}
                    tableTitle="For the Selected Day"
                  />
                )}
              </div>
            </div>
          </TableWrapper>
          <TableWrapper cardTitle="Shiftwise Best performers(For the Selected Month)" >
            <div className="flex-container">
              <div className="flex-item-2 mb-0">
                {dasboardData["achieversData"] && (
                  <AchieversTable
                    achieversData={dasboardData["achieversData"]["shift1"]}
                    tableTitle="Shift-1"
                    isAchiever={true}
                  />
                )}
              </div>
              <div className="flex-item-2 mb-0">
                {dasboardData["achieversData"] && (
                  <AchieversTable
                    achieversData={dasboardData["achieversData"]["shift2"]}
                    tableTitle="Shift-2"
                    isAchiever={true}
                  />
                )}
              </div>
            </div>
          </TableWrapper>
        </div>
      </div>
    </>
  );
};

export default React.memo(Jobs);

