import React from "react";
import { Typography } from "@material-ui/core";
import BarChart from "../../components/Charts/BarChart/BarChart";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles } from "./styles";
import { idleTimeData } from "./data";
import { rejectionData } from "./data2";
import * as CHART_UTILS from "../../components/Charts/BarChart/BarChart.constant";

const chartTitle2 = rejectionData["rejectionTrend"] ? `Rejection Trend(For the selected Month)` : "";
  const chartLabels2 = rejectionData["rejectionTrend"]
    ? rejectionData["rejectionTrend"].map((el) => el["date_of_job"])
    : [];
  const dataGroup2 = rejectionData["rejectionTrend"]
    ? [
      {
        ...CHART_UTILS.BAR,
        label: "Day-Trend",
        data: rejectionData["rejectionTrend"].map(
          (el) => el["daily_rejection"]
        ),
        pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        type: "line",
        fill: false,
        backgroundColor: "#3A8B9C",
        borderColor: 'rgb(0, 100, 192)',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        // pointStyle: "circle",
        // pointBackgroundColor: "rgba(0, 0, 0, 0)",
        // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day",
          data: rejectionData["rejectionTrend"].map(
            (el) => el["daily_rejection"]
          ),
          backgroundColor: "#df00ff",
          borderColor: "#df00ff",
        },
      ]
    : [];

const chartTitle = idleTimeData["idleTimeTrend"] ? `Idle-Time Trend(For the selected Month)` : "";

  const chartLabels = idleTimeData["idleTimeTrend"]
    ? idleTimeData["idleTimeTrend"].map((el) => el["date_of_job"])
    : [];

  const dataGroup = idleTimeData["idleTimeTrend"]
    ? [
      {
        ...CHART_UTILS.BAR,
        label: "Day-Trend",
        data: idleTimeData["idleTimeTrend"].map((el) => el["daily_idletime"]),
        pointHoverRadius: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
        type: "line",
        fill: false,
        backgroundColor: "#0b84a5",
        borderColor: 'rgb(0, 100, 192)',
        borderWidth: 1,
        tension: 0,
        pointRadius: 2,
        // pointStyle: "circle",
        // pointBackgroundColor: "rgba(0, 0, 0, 0)",
        // pointBorderColor: "rgba(0, 0, 0, 0)"
        },
        {
          ...CHART_UTILS.BAR,
          label: "Day",
          data: idleTimeData["idleTimeTrend"].map((el) => el["daily_idletime"]),
          backgroundColor: "#df00ff",
          borderColor: "#df00ff",
        },
      ]
    : [];

const Models = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">Idletime & Rejection</Typography>
          <div className={classes.subTitleContainer}>
            {/* <Typography variant="h6" className={classes.subTitle}>Dasboard</Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} /> */}
            <Typography variant="h6" className={classes.subTitle}>
              Machine Shop
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} />
            <Typography variant="h6" className={classes.subTitle}>
             Idletime & Rejection
            </Typography>
          </div>
        </div>
        {/* <div className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.submit}
            //onClick={}
            disableFocusRipple
          >
            + New Dataset
          </Button>
        </div> */}
        <div className="max-container">
          <BarChart
            chartTitle={chartTitle}
            chartLabels={chartLabels}
            dataGroup={dataGroup}
            //iconClass="fa fa-line-chart"
            xLabel="DAYS"
            yLabel="IDLETIME(min)"
          />
           <BarChart
            chartTitle={chartTitle2}
            chartLabels={chartLabels2}
            dataGroup={dataGroup2}
            //iconClass="fa fa-bar-chart"
            xLabel="DAYS"
            yLabel="ACHIEVED QUANTITY"
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Models);
