import React from "react";
import { Link } from "react-router-dom";
import RadialGauge from "../RadialGauge/RadialGauge";
import "./MachineCard.css"

const MachineCard = ({value, name, target, achieved, rejection, idletime}) => {
    
    const achievedClass = (tr, ach) => {
        const per = ach * 100 / tr;
        let cls = per <= 25 ? "dot red" : per > 25 && per <=75 ? "dot orange" : "dot green";
        // console.log("Testing ",cls);
        return cls;
    }
    const rejIdleClass = (tr) => {
        let cls = tr <= 25 ? "dot green" : tr > 25 && tr <=50 ? "dot orange" : "dot red";
        // console.log("Testing ",cls);
        return cls;
    }

    return (
      <Link className="link-tag" to={`/dashboard/machinereport`}>
        <div className="container guage-container border-green shadow">
          <div className="flex-container" style={{
            width: "auto"
          }}>
          <RadialGauge value={value} />
            <div className="gauge-item-2">
              <h4 className="h4-margin ml-sm">{name}</h4>

              <table className="ml-xs">
                <tbody>
                  <tr>
                    <td className="mw-15">
                      {/* <span className="dot orange"></span> */}
                    </td>
                    <td className="mw-md">Target</td>
                    <td className="bold">{target}</td>
                  </tr>
                  <tr>
                    <td className="mw-15">
                      {/* <span className={achievedClass(target, achieved)}></span> */}
                    </td>
                    <td className="mw-md">Achieved</td>
                    <td className="bold">{achieved}</td>
                  </tr>
                  <tr>
                    <td className="mw-15">
                      {/* <span className={rejIdleClass(rejection)}></span> */}
                    </td>
                    <td className="mw-md">Rejection</td>
                    <td className="bold">{rejection}</td>
                  </tr>
                  <tr>
                    <td className="mw-15">
                      {/* <span className={rejIdleClass(idletime)}></span> */}
                    </td>
                    <td className="mw-md">Idletime</td>
                    <td className="bold">{idletime} mins</td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
      </Link>
    );
}
export default MachineCard