import React from "react";

const TableWrapper = ({cardTitle, iconClass, children, shiftTarget}) => {
  return (
    <div className="card-container flex-row mt-24 shadow">
      <div className="card-header bg-light">
        <h3>
          {cardTitle}
          <span className="title-icons">
            <i className={iconClass}></i>
          </span>
        </h3>
        {shiftTarget || shiftTarget === 0 ? (
          <h3 style={{ display: "flex", justifyContent: "flex-end" }}>
            <span>
              <i
                className="fa fa-bullseye"
                style={{ marginRight: "5px" }}
                aria-hidden="true"
              ></i>
            </span>
            Shift Target : {shiftTarget}
          </h3>
        ) : null}
      </div>
      <div className="card-body ">{children}</div>
    </div>
  );
};

export default TableWrapper;
