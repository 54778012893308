import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  pageContainer : {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width:"100%",
    marginBottom: 20,
    padding: "0px 24px 0px 24px",
   },
  titleContainer:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems:"centetr",
    padding:"10px 0 10px 0",
   },
  subTitleContainer:{
    display: "flex",
    justifyContent: "left",
    marginTop:"0.5rem",
    alignItems:"center"
  },
  subTitle:{
    marginRight: "5px",
    //fontWeight: "bold",
    color: theme.palette.primary.light,
  },
  subTitleIcon:
  {
    marginRight: "5px",
    fontSize:"0.8rem",
    color: theme.palette.primary.light,
  },
  tableContainer:{
    display: "flex",
    width:"100%"
  },
  btnContainer:{
    display: "flex",
    alignSelf:"left",
    margin : 0,
  },
    dataGrid: {
      backgroundColor: theme.palette.common.white,
      fontSize: 16,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      marginBottom : 0,
      borderRadius: "5px",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
      },
    },
    modalButton: {
      alignSelf: "flex-end",
      maxWidth: "150px",
      margin: "0 auto",
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
      "&.MuiButtonBase-root.Mui-disabled": {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
      },
      width: "100%",
      marginBottom: 0,
    },
    modalBody: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: 600,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 3, 2),
    },
  }));