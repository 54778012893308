import React from "react";
import { Typography } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MachineCard from "../../../components/MachineCard/MachineCard";
import { useStyles } from "./styles";
import { machinesData } from "./data";

const PublicDatasets = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.pageContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h3">Machines Report(For the selected Day)</Typography>
          <div className={classes.subTitleContainer}>
            {/* <Typography variant="h6" className={classes.subTitle}>Dasboard</Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} /> */}
            <Typography variant="h6" className={classes.subTitle}>
              Machine Shop
            </Typography>
            <ArrowForwardIosIcon className={classes.subTitleIcon} />
            <Typography variant="h6" className={classes.subTitle}>
              Machines Report
            </Typography>
          </div>
        </div>
   
        <div className="max-container">
        <div className="flex-container">
            {machinesData["demographyData"]?.length !== 0 &&
              machinesData["demographyData"]?.map((machine) => (
                <div key={machine["machine"]} className="flex-item-3">
                  <MachineCard
                    value={machine["oee"]}
                    name={machine["machine"]}
                    target={machine["daily_target"]}
                    achieved={machine["achieved_qty"]}
                    rejection={machine["rejection_total"]}
                    idletime={machine["idletime_total"]}
                  />
                </div>
              ))}
          </div>
        
        </div>
      </div>
    </>
  );
};

export default React.memo(PublicDatasets);
