import { styled } from "@material-ui/core";
//import MainNavbar from "./MainNavbar";

const MainLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  borderRadius: "20px",
}));

// const MainLayoutWrapper = styled("div")({
//   //display: "flex",
//   flex: "1 1 auto",
//   overflow: "hidden",
//   //paddingTop: 64,
// });

// const MainLayoutContainer = styled("div")({
//   //display: "flex",
//   flex: "1 1 auto",
//   overflow: "auto",
// });

// const MainLayoutContent = styled("div")({
//   //flex: "1 1 auto",
//   height: "100%",
//   overflow: "auto",
// });

const MainLayout = (props) => (
  <MainLayoutRoot>
     {props.children}
  </MainLayoutRoot>
  // <MainLayoutRoot>
  //   {/* <MainNavbar /> */}
  //   <MainLayoutWrapper>
  //     <MainLayoutContainer>
  //       <MainLayoutContent>{props.children}</MainLayoutContent>
  //     </MainLayoutContainer>
  //   </MainLayoutWrapper>
  // </MainLayoutRoot>
);

export default MainLayout;
