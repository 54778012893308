import React from "react";
import { Box, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from '@material-ui/icons/Person';

import { useStyles } from "./styles";

const UserProfile = ({isOpen}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.userBox}>
        <div className={classes.profile}>
          <PersonIcon  style={{ color: "#fff", fontSize: "30px" }} />
        </div>
        <div style={{ cursor: "pointer", display: "flex" }}>
          <ExitToAppIcon style={{ color: "#fff" }} />
          <Typography
            variant="h6"
            style={{
              display: isOpen ? "" : "none",
              color: "#fff",
              marginLeft: "10px",
            }}
          >
            Sign out
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default UserProfile;
