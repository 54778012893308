export const dasboardData = {
    "success": true,
    "oeeTrend": [
        {
            "date_of_job": "01/07/2021",
            "s1_oee": 74,
            "s2_oee": 70,
            "daily_oee": 72
        },
        {
            "date_of_job": "02/07/2021",
            "s1_oee": 72,
            "s2_oee": 70,
            "daily_oee": 71
        },
        {
            "date_of_job": "03/07/2021",
            "s1_oee": 83,
            "s2_oee": 79,
            "daily_oee": 81
        },
        {
            "date_of_job": "05/07/2021",
            "s1_oee": 66,
            "s2_oee": 62,
            "daily_oee": 64
        },
        {
            "date_of_job": "06/07/2021",
            "s1_oee": 48,
            "s2_oee": 70,
            "daily_oee": 58
        },
        {
            "date_of_job": "07/07/2021",
            "s1_oee": 69,
            "s2_oee": 59,
            "daily_oee": 64
        },
        {
            "date_of_job": "08/07/2021",
            "s1_oee": 60,
            "s2_oee": 58,
            "daily_oee": 59
        },
        {
            "date_of_job": "09/07/2021",
            "s1_oee": 53,
            "s2_oee": 63,
            "daily_oee": 58
        },
        {
            "date_of_job": "10/07/2021",
            "s1_oee": 47,
            "s2_oee": 51,
            "daily_oee": 49
        },
        {
            "date_of_job": "12/07/2021",
            "s1_oee": 56,
            "s2_oee": 66,
            "daily_oee": 60
        },
        {
            "date_of_job": "13/07/2021",
            "s1_oee": 45,
            "s2_oee": 58,
            "daily_oee": 52
        },
        {
            "date_of_job": "14/07/2021",
            "s1_oee": 64,
            "s2_oee": 75,
            "daily_oee": 70
        },
        {
            "date_of_job": "15/07/2021",
            "s1_oee": 63,
            "s2_oee": 62,
            "daily_oee": 62
        },
        {
            "date_of_job": "16/07/2021",
            "s1_oee": 66,
            "s2_oee": 62,
            "daily_oee": 64
        },
        {
            "date_of_job": "17/07/2021",
            "s1_oee": 56,
            "s2_oee": 62,
            "daily_oee": 59
        },
        {
            "date_of_job": "19/07/2021",
            "s1_oee": 61,
            "s2_oee": 68,
            "daily_oee": 65
        },
        {
            "date_of_job": "20/07/2021",
            "s1_oee": 53,
            "s2_oee": 67,
            "daily_oee": 60
        },
        {
            "date_of_job": "21/07/2021",
            "s1_oee": 59,
            "s2_oee": 69,
            "daily_oee": 64
        },
        {
            "date_of_job": "22/07/2021",
            "s1_oee": 77,
            "s2_oee": 74,
            "daily_oee": 76
        },
        {
            "date_of_job": "23/07/2021",
            "s1_oee": 73,
            "s2_oee": 73,
            "daily_oee": 73
        },
        {
            "date_of_job": "24/07/2021",
            "s1_oee": 76,
            "s2_oee": 77,
            "daily_oee": 77
        },
        {
            "date_of_job": "25/07/2021",
            "s1_oee": 72,
            "s2_oee": 73,
            "daily_oee": 72
        },
        {
            "date_of_job": "26/07/2021",
            "s1_oee": 78,
            "s2_oee": 70,
            "daily_oee": 74
        },
        {
            "date_of_job": "27/07/2021",
            "s1_oee": 76,
            "s2_oee": 68,
            "daily_oee": 72
        },
        {
            "date_of_job": "28/07/2021",
            "s1_oee": 70,
            "s2_oee": 62,
            "daily_oee": 66
        },
        {
            "date_of_job": "29/07/2021",
            "s1_oee": 72,
            "s2_oee": 73,
            "daily_oee": 72
        },
        {
            "date_of_job": "30/07/2021",
            "s1_oee": 55,
            "s2_oee": 78,
            "daily_oee": 67
        }
    ],
    "prodQtyOpwise": {
        "monthlyData": [
            {
                "op_type": "AM201",
                "quantity": 26202,
                "target": 216202,
                "percentage": "12%"
            },
            {
                "op_type": "AM202",
                "quantity": 352367,
                "target": 484482,
                "percentage": "72%"
            },
            {
                "op_type": "AM203",
                "quantity": 282331,
                "target": 351127,
                "percentage": "80%"
            },
            {
                "op_type": "AM204",
                "quantity": 440606,
                "target": 540599,
                "percentage": "81%"
            },
            {
                "op_type": "AM205",
                "quantity": 872,
                "target": 1479,
                "percentage": "58%"
            },
            {
                "op_type": "AM206",
                "quantity": 437,
                "target": 20168,
                "percentage": "2%"
            }
        ],
        "dailyData": [
            {
                "date": "2021-07-01",
                "op_type": "AM201",
                "quantity": 410,
                "target": 10356,
                "percentage": "3%"
            },
            {
                "date": "2021-07-01",
                "op_type": "AM202",
                "quantity": 15286,
                "target": 19976,
                "percentage": "76%"
            },
            {
                "date": "2021-07-01",
                "op_type": "AM203",
                "quantity": 11606,
                "target": 13249,
                "percentage": "87%"
            },
            {
                "date": "2021-07-01",
                "op_type": "AM204",
                "quantity": 16690,
                "target": 21011,
                "percentage": "79%"
            },
            {
                "date": "2021-07-01",
                "op_type": "AM205",
                "quantity": 672,
                "target": 1083,
                "percentage": "62%"
            }
        ],
        "shiftTarget": {
            "01/07/2021": {
                "shift1": {
                    "BHADRA": 3240,
                    "HARA": 895,
                    "HARI": 926,
                    "HAYAGREE": 1236,
                    "KAVERI": 1560,
                    "MADHAVA": 1041,
                    "NARASIMH": 1493,
                    "NARAYANA": 1538,
                    "RAMA": 1317,
                    "SHARVA": 1485,
                    "TUNGA": 1560,
                    "VAMANA": 3216,
                    "KRISHNA": 1091,
                    "LAKSHMAN": 1458,
                    "HRISHIKE": 1418,
                    "CHATURBH": 3504,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 1902,
                    "HARI": 2000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 2166,
                    "NARASIMH": 1500,
                    "NARAYANA": 1464,
                    "RAMA": 1416,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1326,
                    "CHATURBH": 2052,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "02/07/2021": {
                "shift1": {
                    "BHADRA": 1577,
                    "HARA": 815,
                    "HARI": 933,
                    "HAYAGREE": 1161,
                    "KAVERI": 1560,
                    "MADHAVA": 920,
                    "NARASIMH": 909,
                    "NARAYANA": 1360,
                    "RAMA": 1471,
                    "SHARVA": 2928,
                    "TUNGA": 1595,
                    "VAMANA": 1395,
                    "KRISHNA": 2359,
                    "LAKSHMAN": 1202,
                    "HRISHIKE": 1296,
                    "CHATURBH": 4104,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 866,
                    "HARI": 607,
                    "HAYAGREE": 1212,
                    "KAVERI": 1560,
                    "MADHAVA": 1778,
                    "NARASIMH": 1292,
                    "NARAYANA": 1344,
                    "RAMA": 1560,
                    "SHARVA": 1036,
                    "TUNGA": 1567,
                    "VAMANA": 2669,
                    "KRISHNA": 931,
                    "LAKSHMAN": 812,
                    "HRISHIKE": 1418,
                    "CHATURBH": 1338,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "05/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1488,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 3250,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1193,
                    "HRISHIKE": 1418,
                    "CHATURBH": 2052
                },
                "shift2": {
                    "BHADRA": 1400,
                    "HARA": 810,
                    "HARI": 2000,
                    "HAYAGREE": 1187,
                    "KAVERI": 1560,
                    "MADHAVA": 935,
                    "NARASIMH": 1405,
                    "NARAYANA": 1454,
                    "RAMA": 1336,
                    "SHARVA": 1132,
                    "TUNGA": 1435,
                    "VAMANA": 1274,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 812,
                    "HRISHIKE": 1154,
                    "CHATURBH": 1626
                }
            },
            "06/07/2021": {
                "shift1": {
                    "BHADRA": 1300,
                    "HARA": 769,
                    "HARI": 713,
                    "HAYAGREE": 1190,
                    "KAVERI": 1560,
                    "MADHAVA": 710,
                    "NARASIMH": 1126,
                    "NARAYANA": 1180,
                    "RAMA": 1123,
                    "SHARVA": 1178,
                    "TUNGA": 1225,
                    "VAMANA": 1096,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 170,
                    "HRISHIKE": 894,
                    "CHATURBH": 1566,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1567,
                    "HARA": 989,
                    "HARI": 907,
                    "HAYAGREE": 1289,
                    "KAVERI": 0,
                    "MADHAVA": 1061,
                    "NARASIMH": 1470,
                    "NARAYANA": 1540,
                    "RAMA": 1480,
                    "SHARVA": 1440,
                    "TUNGA": 1592,
                    "VAMANA": 1259,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1414,
                    "HRISHIKE": 1418,
                    "CHATURBH": 1762,
                    "DURGA": 3150,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "07/07/2021": {
                "shift1": {
                    "BHADRA": 1562,
                    "HARA": 963,
                    "HARI": 698,
                    "HAYAGREE": 1241,
                    "KAVERI": 1956,
                    "MADHAVA": 1083,
                    "NARASIMH": 1195,
                    "NARAYANA": 1022,
                    "RAMA": 1224,
                    "SHARVA": 1070,
                    "TUNGA": 1535,
                    "VAMANA": 1165,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 761,
                    "HRISHIKE": 1316,
                    "CHATURBH": 2052,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1572,
                    "HARA": 983,
                    "HARI": 2000,
                    "HAYAGREE": 1255,
                    "KAVERI": 1560,
                    "MADHAVA": 990,
                    "NARASIMH": 1500,
                    "NARAYANA": 1560,
                    "RAMA": 1080,
                    "SHARVA": 1305,
                    "TUNGA": 1595,
                    "VAMANA": 3216,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1386,
                    "HRISHIKE": 1333,
                    "CHATURBH": 1980,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "08/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 2000,
                    "HAYAGREE": 800,
                    "KAVERI": 1560,
                    "MADHAVA": 343,
                    "NARASIMH": 1500,
                    "NARAYANA": 1560,
                    "RAMA": 708,
                    "SHARVA": 1512,
                    "TUNGA": 1625,
                    "VAMANA": 1397,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1114,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2052,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1547,
                    "HARA": 2000,
                    "HARI": 983,
                    "HAYAGREE": 1483,
                    "KAVERI": 3120,
                    "MADHAVA": 2166,
                    "NARASIMH": 1336,
                    "NARAYANA": 1543,
                    "RAMA": 1435,
                    "SHARVA": 1420,
                    "TUNGA": 1595,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 996,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2593,
                    "DURGA": 1575,
                    "LAKSHMI": 3150,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "09/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1069,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1560,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1229,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1525,
                    "HARA": 912,
                    "HARI": 940,
                    "HAYAGREE": 2593,
                    "KAVERI": 3120,
                    "MADHAVA": 2166,
                    "NARASIMH": 1500,
                    "NARAYANA": 1459,
                    "RAMA": 1116,
                    "SHARVA": 1528,
                    "TUNGA": 1592,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1400,
                    "HRISHIKE": 1322,
                    "CHATURBH": 4689,
                    "DURGA": 3150,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "10/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1916,
                    "HARI": 1804,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 810,
                    "NARAYANA": 1144,
                    "RAMA": 1099,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 888,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 323,
                    "HRISHIKE": 1322,
                    "CHATURBH": 4268,
                    "DURGA": 1575,
                    "LAKSHMI": 3150,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 2000,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1560,
                    "RAMA": 1080,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 2783,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 3150,
                    "LAKSHMI": 1575,
                    "SARASWAT": 3150,
                    "GOWRI": 1575
                }
            },
            "12/07/2021": {
                "shift1": {
                    "BHADRA": 1560,
                    "HARA": 1952,
                    "HARI": 952,
                    "HAYAGREE": 1151,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 2956,
                    "SHARVA": 1524,
                    "TUNGA": 1595,
                    "VAMANA": 1098,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 2942,
                    "HRISHIKE": 1322,
                    "CHATURBH": 5472,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 3150,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 907,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 916,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "13/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 3150,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 3120,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1264,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 700,
                    "HARA": 1000,
                    "HARI": 975,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "14/07/2021": {
                "shift1": {
                    "BHADRA": 1557,
                    "HARA": 981,
                    "HARI": 2000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 3120,
                    "SHARVA": 1473,
                    "TUNGA": 1507,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 2000,
                    "HARI": 958,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1151,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "15/07/2021": {
                "shift1": {
                    "BHADRA": 1215,
                    "HARA": 898,
                    "HAYAGREE": 1420,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1345,
                    "NARAYANA": 1340,
                    "RAMA": 1411,
                    "SHARVA": 1272,
                    "TUNGA": 1280,
                    "VAMANA": 1336,
                    "LAKSHMAN": 946,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2475,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "GOWRI": 1575,
                    "SARASWAT": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 2000,
                    "HARI": 969,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1327,
                    "TUNGA": 1400,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 2644,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "GOWRI": 1575,
                    "SARASWAT": 1575
                }
            },
            "16/07/2021": {
                "shift1": {
                    "BHADRA": 3100,
                    "HARA": 1000,
                    "HARI": 972,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 941,
                    "NARASIMH": 1486,
                    "NARAYANA": 1467,
                    "RAMA": 1548,
                    "SHARVA": 1483,
                    "TUNGA": 1475,
                    "VAMANA": 1326,
                    "LAKSHMAN": 2958,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 3150,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 938,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 887,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "03/07/2021": {
                "shift1": {
                    "BHADRA": 1555,
                    "HARA": 949,
                    "HARI": 1000,
                    "HAYAGREE": 2302,
                    "KAVERI": 1560,
                    "MADHAVA": 1036,
                    "NARASIMH": 1430,
                    "NARAYANA": 1512,
                    "RAMA": 1442,
                    "SHARVA": 2616,
                    "TUNGA": 1595,
                    "VAMANA": 1360,
                    "KRISHNA": 1083,
                    "LAKSHMAN": 2476,
                    "HRISHIKE": 1282,
                    "CHATURBH": 1951,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1478,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1560,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "KRISHNA": 1218,
                    "LAKSHMAN": 1435,
                    "HRISHIKE": 1418,
                    "CHATURBH": 2052,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "17/07/2021": {
                "shift1": {
                    "BHADRA": 1482,
                    "HARA": 1000,
                    "HARI": 940,
                    "HAYAGREE": 1575,
                    "KAVERI": 3120,
                    "MADHAVA": 1005,
                    "NARASIMH": 1396,
                    "NARAYANA": 1423,
                    "RAMA": 1536,
                    "SHARVA": 1135,
                    "TUNGA": 2237,
                    "VAMANA": 1219,
                    "LAKSHMAN": 1442,
                    "HRISHIKE": 1273,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1200,
                    "HARA": 1000,
                    "HARI": 923,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 2937,
                    "VAMANA": 1608,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "19/07/2021": {
                "shift1": {
                    "BHADRA": 3250,
                    "HARA": 2000,
                    "HARI": 900,
                    "HAYAGREE": 1575,
                    "KAVERI": 3120,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 3250,
                    "VAMANA": 1353,
                    "KRISHNA": 2436,
                    "": 1218,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1241,
                    "HRISHIKE": 2644,
                    "CHATURBH": 2736
                },
                "shift2": {
                    "BHADRA": 1530,
                    "HARA": 1000,
                    "HARI": 1836,
                    "HAYAGREE": 1575,
                    "KAVERI": 1514,
                    "MADHAVA": 925,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1284,
                    "TUNGA": 1600,
                    "VAMANA": 1375,
                    "GOVINDA": 1147,
                    "LAKSHMAN": 2746,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 3150,
                    "SARASWAT": 1575
                }
            },
            "20/07/2021": {
                "shift1": {
                    "BHADRA": 1560,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 913,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575
                },
                "shift2": {
                    "BHADRA": 1380,
                    "HARA": 986,
                    "HARI": 955,
                    "HAYAGREE": 1212,
                    "KAVERI": 1528,
                    "MADHAVA": 940,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1478,
                    "SHARVA": 1425,
                    "TUNGA": 1585,
                    "VAMANA": 1207,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1213,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575
                }
            },
            "21/07/2021": {
                "shift1": {
                    "BHADRA": 1587,
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1560,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1183,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "": 3150,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 3150
                },
                "shift2": {
                    "BHADRA": 1567,
                    "HARA": 984,
                    "HARI": 2000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1406,
                    "MADHAVA": 858,
                    "NARASIMH": 1333,
                    "NARAYANA": 1488,
                    "RAMA": 1483,
                    "SHARVA": 1204,
                    "TUNGA": 1610,
                    "VAMANA": 1239,
                    "GOVINDA": 1183,
                    "LAKSHMAN": 1179,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2429,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "22/07/2021": {
                "shift1": {
                    "HARA": 1000,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1525,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "HARA": 987,
                    "HARI": 1000,
                    "HAYAGREE": 1575,
                    "KAVERI": 1360,
                    "MADHAVA": 1083,
                    "NARASIMH": 1430,
                    "NARAYANA": 1416,
                    "RAMA": 1492,
                    "SHARVA": 1440,
                    "TUNGA": 1625,
                    "VAMANA": 1583,
                    "GOVINDA": 1179,
                    "LAKSHMAN": 1458,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "23/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1625,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1535,
                    "HARA": 1000,
                    "HARI": 1505,
                    "HAYAGREE": 2813,
                    "KAVERI": 1444,
                    "MADHAVA": 1066,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1291,
                    "SHARVA": 1286,
                    "TUNGA": 1625,
                    "VAMANA": 1457,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1354,
                    "HRISHIKE": 992,
                    "CHATURBH": 2564,
                    "DURGA": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "24/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1625,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1250,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1565,
                    "HARA": 1000,
                    "HARI": 1582,
                    "HAYAGREE": 2767,
                    "KAVERI": 1404,
                    "MADHAVA": 1058,
                    "NARASIMH": 1500,
                    "NARAYANA": 1488,
                    "RAMA": 1346,
                    "SHARVA": 1454,
                    "TUNGA": 1597,
                    "VAMANA": 1479,
                    "GOVINDA": 1186,
                    "LAKSHMAN": 1384,
                    "HRISHIKE": 1313,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "25/07/2021": {
                "shift1": {
                    "BHADRA": 1537,
                    "HARA": 1000,
                    "HARI": 1412,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1202,
                    "HARA": 1000,
                    "HARI": 1625,
                    "HAYAGREE": 2753,
                    "KAVERI": 1444,
                    "MADHAVA": 2166,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1416,
                    "SHARVA": 1459,
                    "TUNGA": 1575,
                    "VAMANA": 1321,
                    "GOVINDA": 1198,
                    "LAKSHMAN": 1407,
                    "HRISHIKE": 1281,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "26/07/2021": {
                "shift1": {
                    "BHADRA": 1525,
                    "HARA": 1000,
                    "HARI": 865,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1223,
                    "HRISHIKE": 1322,
                    "CHATURBH": 5472,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1220,
                    "HARA": 970,
                    "HARI": 2407,
                    "HAYAGREE": 1522,
                    "KAVERI": 1384,
                    "MADHAVA": 1045,
                    "NARASIMH": 1435,
                    "NARAYANA": 1470,
                    "RAMA": 1476,
                    "SHARVA": 1380,
                    "TUNGA": 1625,
                    "VAMANA": 1534,
                    "GOVINDA": 1166,
                    "LAKSHMAN": 3000,
                    "HRISHIKE": 1122,
                    "CHATURBH": 2661,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "27/07/2021": {
                "shift1": {
                    "BHADRA": 1365,
                    "HARA": 1000,
                    "HARI": 1260,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1500,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1532,
                    "HARA": 989,
                    "HARI": 1490,
                    "HAYAGREE": 3136,
                    "KAVERI": 1444,
                    "MADHAVA": 1058,
                    "NARASIMH": 1465,
                    "NARAYANA": 1500,
                    "RAMA": 1312,
                    "SHARVA": 1447,
                    "TUNGA": 1600,
                    "VAMANA": 3164,
                    "GOVINDA": 1179,
                    "LAKSHMAN": 2695,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "28/07/2021": {
                "shift1": {
                    "BHADRA": 1607,
                    "HARA": 1000,
                    "HARI": 1125,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 1001,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1068,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1475,
                    "HARA": 950,
                    "HARI": 1502,
                    "HAYAGREE": 727,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1437,
                    "NARAYANA": 1437,
                    "RAMA": 1344,
                    "SHARVA": 1485,
                    "TUNGA": 1552,
                    "VAMANA": 1499,
                    "GOVINDA": 1149,
                    "LAKSHMAN": 2838,
                    "HRISHIKE": 530,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "29/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1625,
                    "HAYAGREE": 1575,
                    "KAVERI": 2888,
                    "MADHAVA": 2166,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1239,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                },
                "shift2": {
                    "BHADRA": 1350,
                    "HARA": 989,
                    "HARI": 1575,
                    "HAYAGREE": 1294,
                    "KAVERI": 1435,
                    "MADHAVA": 1061,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 897,
                    "SHARVA": 1512,
                    "TUNGA": 3165,
                    "VAMANA": 1521,
                    "GOVINDA": 1181,
                    "LAKSHMAN": 1396,
                    "HRISHIKE": 1025,
                    "CHATURBH": 2736,
                    "DURGA": 1575,
                    "LAKSHMI": 1575,
                    "SARASWAT": 1575,
                    "GOWRI": 1575
                }
            },
            "30/07/2021": {
                "shift1": {
                    "BHADRA": 1625,
                    "HARA": 1000,
                    "HARI": 1625,
                    "HAYAGREE": 1575,
                    "KAVERI": 1444,
                    "MADHAVA": 731,
                    "NARASIMH": 1500,
                    "NARAYANA": 1033,
                    "RAMA": 1560,
                    "SHARVA": 1560,
                    "TUNGA": 1625,
                    "VAMANA": 1608,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 860,
                    "HRISHIKE": 1322,
                    "CHATURBH": 2736
                },
                "shift2": {
                    "BHADRA": 1560,
                    "HARA": 1000,
                    "HARI": 1595,
                    "HAYAGREE": 1311,
                    "KAVERI": 1444,
                    "MADHAVA": 1083,
                    "NARASIMH": 1500,
                    "NARAYANA": 1500,
                    "RAMA": 1387,
                    "SHARVA": 1461,
                    "TUNGA": 1547,
                    "VAMANA": 1088,
                    "GOVINDA": 1218,
                    "LAKSHMAN": 1218,
                    "HRISHIKE": 1159,
                    "CHATURBH": 2736
                }
            }
        }
    },
    "prodQtyVariantwise": {
        "monthlyData": [
            {
                "part_type": "NOT SELE",
                "shift1_qty": 13578,
                "shift2_qty": 12624,
                "daily_qty": 26202
            },
            {
                "part_type": "",
                "shift1_qty": 437,
                "shift2_qty": 0,
                "daily_qty": 437
            },
            {
                "part_type": "110OP110",
                "shift1_qty": 1665,
                "shift2_qty": 2270,
                "daily_qty": 3935
            },
            {
                "part_type": "111OP110",
                "shift1_qty": 7710,
                "shift2_qty": 9387,
                "daily_qty": 17097
            },
            {
                "part_type": "201OP110",
                "shift1_qty": 33850,
                "shift2_qty": 38801,
                "daily_qty": 72651
            },
            {
                "part_type": "203OP110",
                "shift1_qty": 33979,
                "shift2_qty": 39186,
                "daily_qty": 73165
            },
            {
                "part_type": "569OP110",
                "shift1_qty": 47731,
                "shift2_qty": 40112,
                "daily_qty": 87843
            },
            {
                "part_type": "571OP110",
                "shift1_qty": 45028,
                "shift2_qty": 52648,
                "daily_qty": 97676
            },
            {
                "part_type": "111OP115",
                "shift1_qty": 200,
                "shift2_qty": 0,
                "daily_qty": 200
            },
            {
                "part_type": "121OP115",
                "shift1_qty": 0,
                "shift2_qty": 672,
                "daily_qty": 672
            },
            {
                "part_type": "110OP130",
                "shift1_qty": 6691,
                "shift2_qty": 6458,
                "daily_qty": 13149
            },
            {
                "part_type": "111OP130",
                "shift1_qty": 3637,
                "shift2_qty": 3900,
                "daily_qty": 7537
            },
            {
                "part_type": "121OP130",
                "shift1_qty": 60,
                "shift2_qty": 0,
                "daily_qty": 60
            },
            {
                "part_type": "201OP130",
                "shift1_qty": 27716,
                "shift2_qty": 28623,
                "daily_qty": 56339
            },
            {
                "part_type": "203OP130",
                "shift1_qty": 31398,
                "shift2_qty": 40438,
                "daily_qty": 71836
            },
            {
                "part_type": "569OP130",
                "shift1_qty": 26958,
                "shift2_qty": 29584,
                "daily_qty": 56542
            },
            {
                "part_type": "571OP130",
                "shift1_qty": 38951,
                "shift2_qty": 37917,
                "daily_qty": 76868
            },
            {
                "part_type": "201OP140",
                "shift1_qty": 44190,
                "shift2_qty": 64074,
                "daily_qty": 108264
            },
            {
                "part_type": "203OP140",
                "shift1_qty": 41665,
                "shift2_qty": 50272,
                "daily_qty": 91937
            },
            {
                "part_type": "569OP140",
                "shift1_qty": 75860,
                "shift2_qty": 64392,
                "daily_qty": 140252
            },
            {
                "part_type": "571OP140",
                "shift1_qty": 48699,
                "shift2_qty": 51454,
                "daily_qty": 100153
            }
        ],
        "dailyData": [
            {
                "date": "2021-07-01",
                "part_type": "NOT SELE",
                "shift1_qty": 72,
                "shift2_qty": 338,
                "daily_qty": 410
            },
            {
                "date": "2021-07-01",
                "part_type": "201OP110",
                "shift1_qty": 1411,
                "shift2_qty": 2215,
                "daily_qty": 3626
            },
            {
                "date": "2021-07-01",
                "part_type": "203OP110",
                "shift1_qty": 1100,
                "shift2_qty": 1097,
                "daily_qty": 2197
            },
            {
                "date": "2021-07-01",
                "part_type": "569OP110",
                "shift1_qty": 2476,
                "shift2_qty": 1193,
                "daily_qty": 3669
            },
            {
                "date": "2021-07-01",
                "part_type": "571OP110",
                "shift1_qty": 2890,
                "shift2_qty": 2904,
                "daily_qty": 5794
            },
            {
                "date": "2021-07-01",
                "part_type": "121OP115",
                "shift1_qty": 0,
                "shift2_qty": 672,
                "daily_qty": 672
            },
            {
                "date": "2021-07-01",
                "part_type": "201OP130",
                "shift1_qty": 1170,
                "shift2_qty": 427,
                "daily_qty": 1597
            },
            {
                "date": "2021-07-01",
                "part_type": "203OP130",
                "shift1_qty": 1811,
                "shift2_qty": 616,
                "daily_qty": 2427
            },
            {
                "date": "2021-07-01",
                "part_type": "569OP130",
                "shift1_qty": 1892,
                "shift2_qty": 1461,
                "daily_qty": 3353
            },
            {
                "date": "2021-07-01",
                "part_type": "571OP130",
                "shift1_qty": 1791,
                "shift2_qty": 2438,
                "daily_qty": 4229
            },
            {
                "date": "2021-07-01",
                "part_type": "201OP140",
                "shift1_qty": 472,
                "shift2_qty": 1204,
                "daily_qty": 1676
            },
            {
                "date": "2021-07-01",
                "part_type": "203OP140",
                "shift1_qty": 2593,
                "shift2_qty": 1328,
                "daily_qty": 3921
            },
            {
                "date": "2021-07-01",
                "part_type": "569OP140",
                "shift1_qty": 3346,
                "shift2_qty": 3583,
                "daily_qty": 6929
            },
            {
                "date": "2021-07-01",
                "part_type": "571OP140",
                "shift1_qty": 812,
                "shift2_qty": 3352,
                "daily_qty": 4164
            }
        ]
    },
    "rejectionIdleTime": {
        "rejection": {
            "monthlyData": {
                "monthName": "July",
                "shift1": 7540,
                "shift2": 9950,
                "total": 17490
            },
            "dailyData": {
                "date": "2021-07-01",
                "shift1": 362,
                "shift2": 258,
                "total": 620
            }
        },
        "idle_time": {
            "monthlyData": {
                "monthName": "July",
                "shift1": 143584,
                "shift2": 127715,
                "total": 271299
            },
            "dailyData": {
                "date": "2021-07-01",
                "shift1": 5080,
                "shift2": 4785,
                "total": 9865
            }
        },
        "rejectionIdleTimeData": [
            {
                "date_of_job": "2021-07-01T00:00:00.000Z",
                "s1_rejection": "362",
                "s2_rejection": "258",
                "s1_idle_time": "5080",
                "s2_idle_time": "4785"
            },
            {
                "date_of_job": "2021-07-02T00:00:00.000Z",
                "s1_rejection": "419",
                "s2_rejection": "162",
                "s1_idle_time": "5040",
                "s2_idle_time": "4735"
            },
            {
                "date_of_job": "2021-07-05T00:00:00.000Z",
                "s1_rejection": "238",
                "s2_rejection": "425",
                "s1_idle_time": "4562",
                "s2_idle_time": "4598"
            },
            {
                "date_of_job": "2021-07-06T00:00:00.000Z",
                "s1_rejection": "332",
                "s2_rejection": "524",
                "s1_idle_time": "6612",
                "s2_idle_time": "4849"
            },
            {
                "date_of_job": "2021-07-07T00:00:00.000Z",
                "s1_rejection": "455",
                "s2_rejection": "521",
                "s1_idle_time": "5252",
                "s2_idle_time": "5609"
            },
            {
                "date_of_job": "2021-07-08T00:00:00.000Z",
                "s1_rejection": "213",
                "s2_rejection": "328",
                "s1_idle_time": "4985",
                "s2_idle_time": "7575"
            },
            {
                "date_of_job": "2021-07-09T00:00:00.000Z",
                "s1_rejection": "233",
                "s2_rejection": "285",
                "s1_idle_time": "7083",
                "s2_idle_time": "6590"
            },
            {
                "date_of_job": "2021-07-10T00:00:00.000Z",
                "s1_rejection": "179",
                "s2_rejection": "395",
                "s1_idle_time": "7607",
                "s2_idle_time": "6926"
            },
            {
                "date_of_job": "2021-07-12T00:00:00.000Z",
                "s1_rejection": "455",
                "s2_rejection": "75",
                "s1_idle_time": "6455",
                "s2_idle_time": "5371"
            },
            {
                "date_of_job": "2021-07-13T00:00:00.000Z",
                "s1_rejection": "176",
                "s2_rejection": "192",
                "s1_idle_time": "6529",
                "s2_idle_time": "5470"
            },
            {
                "date_of_job": "2021-07-14T00:00:00.000Z",
                "s1_rejection": "545",
                "s2_rejection": "131",
                "s1_idle_time": "4741",
                "s2_idle_time": "4081"
            },
            {
                "date_of_job": "2021-07-15T00:00:00.000Z",
                "s1_rejection": "245",
                "s2_rejection": "136",
                "s1_idle_time": "5602",
                "s2_idle_time": "4894"
            },
            {
                "date_of_job": "2021-07-16T00:00:00.000Z",
                "s1_rejection": "229",
                "s2_rejection": "138",
                "s1_idle_time": "5323",
                "s2_idle_time": "4560"
            },
            {
                "date_of_job": "2021-07-03T00:00:00.000Z",
                "s1_rejection": "444",
                "s2_rejection": "242",
                "s1_idle_time": "4996",
                "s2_idle_time": "4179"
            },
            {
                "date_of_job": "2021-07-17T00:00:00.000Z",
                "s1_rejection": "413",
                "s2_rejection": "80",
                "s1_idle_time": "6479",
                "s2_idle_time": "5907"
            },
            {
                "date_of_job": "2021-07-19T00:00:00.000Z",
                "s1_rejection": "163",
                "s2_rejection": "653",
                "s1_idle_time": "6101",
                "s2_idle_time": "4893"
            },
            {
                "date_of_job": "2021-07-20T00:00:00.000Z",
                "s1_rejection": "196",
                "s2_rejection": "527",
                "s1_idle_time": "5442",
                "s2_idle_time": "3415"
            },
            {
                "date_of_job": "2021-07-21T00:00:00.000Z",
                "s1_rejection": "102",
                "s2_rejection": "505",
                "s1_idle_time": "5414",
                "s2_idle_time": "4307"
            },
            {
                "date_of_job": "2021-07-22T00:00:00.000Z",
                "s1_rejection": "214",
                "s2_rejection": "269",
                "s1_idle_time": "4456",
                "s2_idle_time": "3503"
            },
            {
                "date_of_job": "2021-07-23T00:00:00.000Z",
                "s1_rejection": "319",
                "s2_rejection": "501",
                "s1_idle_time": "4232",
                "s2_idle_time": "3388"
            },
            {
                "date_of_job": "2021-07-24T00:00:00.000Z",
                "s1_rejection": "163",
                "s2_rejection": "517",
                "s1_idle_time": "3824",
                "s2_idle_time": "3242"
            },
            {
                "date_of_job": "2021-07-25T00:00:00.000Z",
                "s1_rejection": "204",
                "s2_rejection": "457",
                "s1_idle_time": "5224",
                "s2_idle_time": "4885"
            },
            {
                "date_of_job": "2021-07-26T00:00:00.000Z",
                "s1_rejection": "302",
                "s2_rejection": "497",
                "s1_idle_time": "4323",
                "s2_idle_time": "4082"
            },
            {
                "date_of_job": "2021-07-27T00:00:00.000Z",
                "s1_rejection": "218",
                "s2_rejection": "674",
                "s1_idle_time": "4168",
                "s2_idle_time": "4535"
            },
            {
                "date_of_job": "2021-07-28T00:00:00.000Z",
                "s1_rejection": "254",
                "s2_rejection": "602",
                "s1_idle_time": "4881",
                "s2_idle_time": "5463"
            },
            {
                "date_of_job": "2021-07-29T00:00:00.000Z",
                "s1_rejection": "219",
                "s2_rejection": "535",
                "s1_idle_time": "4347",
                "s2_idle_time": "3518"
            },
            {
                "date_of_job": "2021-07-30T00:00:00.000Z",
                "s1_rejection": "248",
                "s2_rejection": "321",
                "s1_idle_time": "4826",
                "s2_idle_time": "2355"
            }
        ]
    },
    "idleTimeData": {
        "monthlyData": {
            "shift1": {
                "no_load": 33674,
                "no_operator": 28658,
                "no_tool": 3,
                "no_power": 8083,
                "tool_change": 727,
                "under_setting": 5101,
                "under_maintenance": 2579,
                "breakfast_snack": 8826,
                "lunch_dinner": 13105,
                "tea_break": 7053,
                "machine_cleaning": 5499,
                "other": 4342,
                "total_idletime": 117213
            },
            "shift2": {
                "no_load": 28559,
                "no_operator": 33811,
                "no_tool": 0,
                "no_power": 1591,
                "tool_change": 153,
                "under_setting": 2590,
                "under_maintenance": 4260,
                "breakfast_snack": 3981,
                "lunch_dinner": 12338,
                "tea_break": 4883,
                "machine_cleaning": 8670,
                "other": 6243,
                "total_idletime": 106649
            },
            "daily": {
                "no_load": 62233,
                "no_operator": 62469,
                "no_tool": 3,
                "no_power": 9674,
                "tool_change": 880,
                "under_setting": 7691,
                "under_maintenance": 6839,
                "breakfast_snack": 12807,
                "lunch_dinner": 25443,
                "tea_break": 11936,
                "machine_cleaning": 14169,
                "other": 10585,
                "total_idletime": 223862
            }
        },
        "dailyData": {
            "shift1": {
                "no_load": 1517,
                "no_operator": 1424,
                "no_tool": 0,
                "no_power": 40,
                "tool_change": 0,
                "under_setting": 5,
                "under_maintenance": 167,
                "breakfast_snack": 409,
                "lunch_dinner": 559,
                "tea_break": 252,
                "machine_cleaning": 228,
                "other": 295,
                "total_idletime": 4877
            },
            "shift2": {
                "no_load": 1087,
                "no_operator": 1058,
                "no_tool": 0,
                "no_power": 0,
                "tool_change": 0,
                "under_setting": 95,
                "under_maintenance": 390,
                "breakfast_snack": 189,
                "lunch_dinner": 476,
                "tea_break": 226,
                "machine_cleaning": 590,
                "other": 186,
                "total_idletime": 4280
            },
            "daily": {
                "no_load": 2604,
                "no_operator": 2482,
                "no_tool": 0,
                "no_power": 40,
                "tool_change": 0,
                "under_setting": 100,
                "under_maintenance": 557,
                "breakfast_snack": 598,
                "lunch_dinner": 1035,
                "tea_break": 478,
                "machine_cleaning": 818,
                "other": 481,
                "total_idletime": 9157
            }
        }
    },
    "rejectionData": {
        "monthlyData": {
            "OP110": {
                "shift1": {
                    "id_os": 3,
                    "id_poor_finish": 12,
                    "id_unwash": 87,
                    "id_blow_hole": 111,
                    "width_us_os": 56,
                    "id_perpendicularity_issue": 232,
                    "more_chamfer": 21,
                    "less_chamfer": 0,
                    "power_failure": 10,
                    "settings_rejection": 3,
                    "total_rejection": 468
                },
                "daily": {
                    "id_os": 39,
                    "id_poor_finish": 79,
                    "id_unwash": 95,
                    "id_blow_hole": 275,
                    "width_us_os": 125,
                    "id_perpendicularity_issue": 404,
                    "more_chamfer": 21,
                    "less_chamfer": 0,
                    "power_failure": 20,
                    "settings_rejection": 3,
                    "total_rejection": 994
                },
                "shift2": {
                    "id_os": 36,
                    "id_poor_finish": 67,
                    "id_unwash": 8,
                    "id_blow_hole": 164,
                    "width_us_os": 69,
                    "id_perpendicularity_issue": 172,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 10,
                    "settings_rejection": 0,
                    "total_rejection": 526
                }
            },
            "OP130": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 28,
                    "id_unwash": 344,
                    "id_blow_hole": 592,
                    "width_us_os": 1502,
                    "id_perpendicularity_issue": 1664,
                    "more_chamfer": 16,
                    "less_chamfer": 93,
                    "power_failure": 9,
                    "settings_rejection": 172,
                    "total_rejection": 4420
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 52,
                    "id_unwash": 1292,
                    "id_blow_hole": 1384,
                    "width_us_os": 3258,
                    "id_perpendicularity_issue": 4517,
                    "more_chamfer": 24,
                    "less_chamfer": 101,
                    "power_failure": 9,
                    "settings_rejection": 648,
                    "total_rejection": 11285
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 24,
                    "id_unwash": 948,
                    "id_blow_hole": 792,
                    "width_us_os": 1756,
                    "id_perpendicularity_issue": 2853,
                    "more_chamfer": 8,
                    "less_chamfer": 8,
                    "power_failure": 0,
                    "settings_rejection": 476,
                    "total_rejection": 6865
                }
            },
            "OP140": {
                "shift1": {
                    "id_os": 365,
                    "id_poor_finish": 715,
                    "id_unwash": 28,
                    "id_blow_hole": 16,
                    "width_us_os": 912,
                    "id_perpendicularity_issue": 149,
                    "more_chamfer": 40,
                    "less_chamfer": 503,
                    "power_failure": 0,
                    "settings_rejection": 17,
                    "total_rejection": 2745
                },
                "daily": {
                    "id_os": 684,
                    "id_poor_finish": 1413,
                    "id_unwash": 75,
                    "id_blow_hole": 208,
                    "width_us_os": 1823,
                    "id_perpendicularity_issue": 332,
                    "more_chamfer": 101,
                    "less_chamfer": 716,
                    "power_failure": 0,
                    "settings_rejection": 204,
                    "total_rejection": 5556
                },
                "shift2": {
                    "id_os": 319,
                    "id_poor_finish": 698,
                    "id_unwash": 47,
                    "id_blow_hole": 192,
                    "width_us_os": 911,
                    "id_perpendicularity_issue": 183,
                    "more_chamfer": 61,
                    "less_chamfer": 213,
                    "power_failure": 0,
                    "settings_rejection": 187,
                    "total_rejection": 2811
                }
            },
            " SELE": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 17,
                    "id_unwash": 12,
                    "id_blow_hole": 29,
                    "width_us_os": 17,
                    "id_perpendicularity_issue": 19,
                    "more_chamfer": 0,
                    "less_chamfer": 22,
                    "power_failure": 7,
                    "settings_rejection": 0,
                    "total_rejection": 110
                },
                "daily": {
                    "id_os": 9,
                    "id_poor_finish": 32,
                    "id_unwash": 13,
                    "id_blow_hole": 29,
                    "width_us_os": 38,
                    "id_perpendicularity_issue": 44,
                    "more_chamfer": 4,
                    "less_chamfer": 47,
                    "power_failure": 7,
                    "settings_rejection": 0,
                    "total_rejection": 210
                },
                "shift2": {
                    "id_os": 9,
                    "id_poor_finish": 15,
                    "id_unwash": 1,
                    "id_blow_hole": 0,
                    "width_us_os": 21,
                    "id_perpendicularity_issue": 25,
                    "more_chamfer": 4,
                    "less_chamfer": 25,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 100
                }
            },
            "OP115": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                }
            },
            "": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                }
            }
        },
        "dailyData": {
            "OP110": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 4,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 18,
                    "more_chamfer": 3,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 14
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 6,
                    "width_us_os": 16,
                    "id_perpendicularity_issue": 34,
                    "more_chamfer": 3,
                    "less_chamfer": 0,
                    "power_failure": 1,
                    "settings_rejection": 0,
                    "total_rejection": 49
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 2,
                    "width_us_os": 16,
                    "id_perpendicularity_issue": 16,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 1,
                    "settings_rejection": 0,
                    "total_rejection": 35
                }
            },
            "OP130": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 2,
                    "id_blow_hole": 53,
                    "width_us_os": 75,
                    "id_perpendicularity_issue": 116,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 12,
                    "total_rejection": 258
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 2,
                    "id_blow_hole": 69,
                    "width_us_os": 123,
                    "id_perpendicularity_issue": 161,
                    "more_chamfer": 5,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 22,
                    "total_rejection": 382
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 16,
                    "width_us_os": 48,
                    "id_perpendicularity_issue": 45,
                    "more_chamfer": 5,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 10,
                    "total_rejection": 124
                }
            },
            "OP140": {
                "shift1": {
                    "id_os": 6,
                    "id_poor_finish": 7,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 4,
                    "id_perpendicularity_issue": 60,
                    "more_chamfer": 2,
                    "less_chamfer": 10,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 89
                },
                "daily": {
                    "id_os": 23,
                    "id_poor_finish": 26,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 4,
                    "id_perpendicularity_issue": 60,
                    "more_chamfer": 2,
                    "less_chamfer": 14,
                    "power_failure": 0,
                    "settings_rejection": 60,
                    "total_rejection": 189
                },
                "shift2": {
                    "id_os": 17,
                    "id_poor_finish": 19,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 4,
                    "power_failure": 0,
                    "settings_rejection": 60,
                    "total_rejection": 100
                }
            },
            " SELE": {
                "shift1": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "daily": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                },
                "shift2": {
                    "id_os": 0,
                    "id_poor_finish": 0,
                    "id_unwash": 0,
                    "id_blow_hole": 0,
                    "width_us_os": 0,
                    "id_perpendicularity_issue": 0,
                    "more_chamfer": 0,
                    "less_chamfer": 0,
                    "power_failure": 0,
                    "settings_rejection": 0,
                    "total_rejection": 0
                }
            }
        }
    },
    "achieversData": {
        "shift1": [
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-1",
                "operator_name": "ANKITH",
                "machine_name": "Machine-13",
                "target": 1458,
                "achieved_qty": 1468
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-5",
                "operator_name": "GANESH",
                "machine_name": "Machine-2",
                "target": 1418,
                "achieved_qty": 1420
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-11",
                "operator_name": "RAMESH",
                "machine_name": "Machine-5",
                "target": 1538,
                "achieved_qty": 1530
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-25",
                "operator_name": "RAKESH",
                "machine_name": "Machine-7",
                "target": 895,
                "achieved_qty": 888
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-20",
                "operator_name": "LOHITH",
                "machine_name": "Machine-8",
                "target": 1091,
                "achieved_qty": 1067
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-19",
                "operator_name": "SHYAMJI",
                "machine_name": "Machine-15",
                "target": 1041,
                "achieved_qty": 1016
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-17",
                "operator_name": "RAMANA",
                "machine_name": "Machine-10",
                "target": 1575,
                "achieved_qty": 1511
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-6",
                "operator_name": "KANCHAN",
                "machine_name": "Machine-14",
                "target": 1452,
                "achieved_qty": 1359
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-16",
                "operator_name": "KARTHIK",
                "machine_name": "Machine-11",
                "target": 1575,
                "achieved_qty": 1460
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-8",
                "operator_name": "RAVI",
                "machine_name": "Machine-6",
                "target": 1485,
                "achieved_qty": 1368
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-19",
                "operator_name": "KUMAR",
                "machine_name": "Machine-1",
                "target": 1560,
                "achieved_qty": 1430
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-22",
                "operator_name": "HALESH",
                "machine_name": "Machine-9",
                "target": 1493,
                "achieved_qty": 1352
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS627",
                "operator_name": "GURUNATH",
                "machine_name": "GOWRI",
                "target": 1575,
                "achieved_qty": 1411
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS456",
                "operator_name": "SURJYA_D",
                "machine_name": "HARI",
                "target": 926,
                "achieved_qty": 811
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS067",
                "operator_name": "ABHILASH",
                "machine_name": "HAYAGREE",
                "target": 1236,
                "achieved_qty": 1044
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS639",
                "operator_name": "AMAN_DPS",
                "machine_name": "BHADRA",
                "target": 1615,
                "achieved_qty": 1197
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS622",
                "operator_name": "LABA_DPS",
                "machine_name": "SARASWAT",
                "target": 1575,
                "achieved_qty": 1100
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS426",
                "operator_name": "ABHISHEK",
                "machine_name": "HAYAGREE",
                "target": 1236,
                "achieved_qty": 256
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS564",
                "operator_name": "BHIMARAV",
                "machine_name": "SHARVA",
                "target": 1485,
                "achieved_qty": 76
            }
        ],
        "shift2": [
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-28",
                "operator_name": "VISHWA",
                "machine_name": "Machine-9",
                "target": 1416,
                "achieved_qty": 1424
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-35",
                "operator_name": "RAVITEJA",
                "machine_name": "Machine-14",
                "target": 1326,
                "achieved_qty": 1328
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-45",
                "operator_name": "MAHESH",
                "machine_name": "Machine-2",
                "target": 1464,
                "achieved_qty": 1450
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-49",
                "operator_name": "RAJU",
                "machine_name": "Machine-7",
                "target": 1500,
                "achieved_qty": 1464
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-29",
                "operator_name": "SIDDAPPA",
                "machine_name": "Machine-10",
                "target": 1083,
                "achieved_qty": 1032
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-36",
                "operator_name": "ROHAN",
                "machine_name": "Machine-4",
                "target": 1575,
                "achieved_qty": 1486
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-38",
                "operator_name": "VINAYAK",
                "machine_name": "Machine-3",
                "target": 1625,
                "achieved_qty": 1525
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-33",
                "operator_name": "ISMAIL",
                "machine_name": "Machine-11",
                "target": 1575,
                "achieved_qty": 1475
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-27",
                "operator_name": "ABDUL",
                "machine_name": "Machine-6",
                "target": 1608,
                "achieved_qty": 1504
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-26",
                "operator_name": "SHARATH",
                "machine_name": "Machine-1",
                "target": 1560,
                "achieved_qty": 1452
            },
            {
                "date": "1/7/2021",
                "operator_id": "AM-OP-43",
                "operator_name": "AMAN",
                "machine_name": "Machine-15 ",
                "target": 1500,
                "achieved_qty": 1352
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS474",
                "operator_name": "PUNITH_D",
                "machine_name": "HAYAGREE",
                "target": 1575,
                "achieved_qty": 1412
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS10021",
                "operator_name": "AMAR_DPS",
                "machine_name": "LAKSHMI",
                "target": 1575,
                "achieved_qty": 1408
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS633",
                "operator_name": "RAJESH_D",
                "machine_name": "TUNGA",
                "target": 1625,
                "achieved_qty": 1450
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS115",
                "operator_name": "MANJUNAT",
                "machine_name": "KRISHNA",
                "target": 1218,
                "achieved_qty": 1030
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS297",
                "operator_name": "BASAVARA",
                "machine_name": "HARI",
                "target": 1000,
                "achieved_qty": 750
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS130",
                "operator_name": "MOHAN_DP",
                "machine_name": "CHATURBH",
                "target": 2052,
                "achieved_qty": 639
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS426",
                "operator_name": "ABHISHEK",
                "machine_name": "HAYAGREE",
                "target": 1575,
                "achieved_qty": 168
            },
            {
                "date": "1/7/2021",
                "operator_id": "NA",
                "operator_name": "DPS",
                "machine_name": "VAMANA",
                "target": 1608,
                "achieved_qty": 76
            },
            {
                "date": "1/7/2021",
                "operator_id": "DPS634",
                "operator_name": "DILIP_DP",
                "machine_name": "LAKSHMI",
                "target": 1575,
                "achieved_qty": 2
            }
        ],
        "targetObj": {
            "shift1": {
                "BHADRA": 1615,
                "HARA": 895,
                "HARI": 926,
                "HAYAGREE": 1236,
                "MADHAVA": 1041,
                "NARASIMH": 1493,
                "NARAYANA": 1538,
                "RAMA": 1560,
                "SHARVA": 1485,
                "TUNGA": 1560,
                "KRISHNA": 1091,
                "LAKSHMAN": 1458,
                "HRISHIKE": 1418,
                "CHATURBH": 1452,
                "DURGA": 1575,
                "LAKSHMI": 1575,
                "SARASWAT": 1575,
                "GOWRI": 1575
            },
            "shift2": {
                "BHADRA": 1625,
                "HARI": 1000,
                "HAYAGREE": 1575,
                "MADHAVA": 1083,
                "NARASIMH": 1500,
                "NARAYANA": 1464,
                "RAMA": 1416,
                "SHARVA": 1560,
                "TUNGA": 1625,
                "VAMANA": 1608,
                "KRISHNA": 1218,
                "LAKSHMAN": 1500,
                "HRISHIKE": 1326,
                "CHATURBH": 2052,
                "DURGA": 1575,
                "LAKSHMI": 1575,
                "SARASWAT": 1575,
                "GOWRI": 1575
            }
        }
    }
}